import React from "react";
import ".././styles/News.css";

function News() {
  return (
    <div>
      <div className="header">
        <div className="text">
          <h1>NEWS</h1>
        </div>
      </div>
      <div className="container-fluid">
        <div className='row my-3'>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card mongo'>
              <a href="Newss/1.jpg" target="_blank">
                <img src="Newss/1.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date" >6 January 2019</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/2.png" target="_blank">
                <img src="Newss/2.png" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >10 March 2019</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/3.jpg" target="_blank">
                <img src="Newss/3.png" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >24 May 2019</p>
            </div>

          </div>
        </div>
        <div className='row my-3'>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/4.png" target="_blank">
                <img src="Newss/4.png" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >3 May 2017</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/5.png" target="_blank">
                <img src="Newss/5.png" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >10 March 2018</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/6.jpeg" target="_blank">
                <img src="Newss/6.jpeg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >19 July 2016</p>
            </div>

          </div>
        </div>
        {/* start */}
        <div className='row my-3'>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/7.jpeg" target="_blank">
                <img src="Newss/7.jpeg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date" >23 December 2018</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/8.jpeg" target="_blank">
                <img src="Newss/8.jpeg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >11 October 2017</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/9.jpeg" target="_blank">
                <img src="Newss/9.jpeg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >31 January 2009</p>
            </div>

          </div>
        </div>
        <div className='row my-3'>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/10.jpeg" target="_blank">
                <img src="Newss/10.jpeg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date" >6 January 2018</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/11.jpg" target="_blank">
                <img src="Newss/11.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >23 June 2013 </p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/15.jpg" target="_blank">
                <img src="Newss/15.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >3 May 2005</p>
            </div>

          </div>
        </div>
        <div className='row my-3'>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/13.jpg" target="_blank">
                <img src="Newss/13.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date" >5 May 2006</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/14.jpg" target="_blank">
                <img src="Newss/14.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >22 September 2019</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/12.jpg" target="_blank">
                <img src="Newss/12.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >24 July 2015</p>
            </div>

          </div>
        </div>
        <div className='row my-3'>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/16.jpg" target="_blank">
                <img src="Newss/16.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date" >7 June 2011</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/17.jpg" target="_blank">
                <img src="Newss/17.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >6 May 2005</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/18.jpg" target="_blank">
                <img src="Newss/18.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >12 September 2010</p>
            </div>

          </div>
        </div>
        <div className='row my-3'>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/19.jpg" target="_blank">
                <img src="Newss/19.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date" >11 Aug 2005</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/20.jpg" target="_blank">
                <img src="Newss/20.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >18 May 2005</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/21.jpg" target="_blank">
                <img src="Newss/21.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >23 March 2005</p>
            </div>

          </div>
        </div>
        <div className='row my-3'>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/22.jpg" target="_blank">
                <img src="Newss/22.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date" >11 April 2005</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/23.jpg" target="_blank">
                <img src="Newss/23.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >26 January 2005</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/24.png" target="_blank">
                <img src="Newss/24.png" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >23 March 2005</p>
            </div>

          </div>
        </div>
        <div className='row my-3'>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/25.jpg" target="_blank">
                <img src="Newss/25.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date" >24 April 2005</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/26.jpg" target="_blank">
                <img src="Newss/26.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >26 January 2005</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/27.jpg" target="_blank">
                <img src="Newss/27.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >23 March 2005</p>
            </div>

          </div>
        </div>
        <div className='row my-3'>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/28.jpg" target="_blank">
                <img src="Newss/28.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date" >11 Aug 2005</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/29.jpg" target="_blank">
                <img src="Newss/29.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >26 January 2005</p>
            </div>

          </div>
          <div className="col-lg-4 md-4 col-sm-12">
            <div className='card'>

              <a href="Newss/31.jpg" target="_blank">
                <img src="Newss/31.jpg" alt="news" style={{ height: "300px", width: "100%" }} />
              </a>
              <p className="font-weight-bold text-white date " >23 March 2005</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default News;
