import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

function Cleanair() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <div className="header">
        <div className="text">
          <h1 className="major">POLLUTED AIR TO CLEAN AIR</h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {/* hkkk */}
          <div className="col-lg-9 col-sm-12">
            <div className="row my-5 happen">
              <h3 className="mb-3 mx-4 jack text-dark">
                Kalpvriksha (Clean Air)
              </h3>
              <div className="hello mt-2">
                <a className="btn-success p-2" href="pdf/PowerPoint Presentation.pdf" target="_blank">
                  <InsertDriveFileIcon className="mb-1" /> View/Download Company Profiles
                </a>
              </div>
            </div>
            {/* </div> */}
            {/* </div> */}
            <iframe
              width="100%"
              height="415"
              className="vansh"
              src="https://www.youtube.com/embed/DLBHLXHTc3M"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              title="Company profile"
              allowfullscreen
            ></iframe>
            <p className="text-left my-3">
              A normal human being breathes an average of 500 ml of air in 1
              normal (or relax) breathing. The lungs already have around 3
              litres of reserve breathing. On average, a person at rest takes
              about 16 breaths per minute. So we take around 8 litres of air
              every minute, 480 litres every hour, over 11500 Litres of toxic
              air every day
            </p>
            <h4 className="font-weight-bold text-danger text-left">
              Toxic Air infiltrates Brain & cause lung cancer & heart attack.
            </h4>
            {/* <img src="About/1.jpg" width="100%" height="400px"></img> */}
            <p className="text-left my-3">
              Every day 90% of cities' inhabitants breathe polluted air which
              becomes the cause of 21 lac deaths. A toxic particle found in
              polluted urban areas can infiltrate the brain, potentially
              contributing to degenerative diseases like Alzheimer’s. Respirable
              particulate matter-PM shortens the life span by half a year. PM is
              the cause of every fifth death in India. Cleaning outdoor air and
              removing PM1, PM2.5, PM10 and airborne heavy metals like lead and
              nickel is hard to achieve as none of existing technology provides
              long term outdoor solutions. Expensive indoor purifiers solve
              localized problem only.
            </p>
            <h4 className="font-weight-bold">
              Introducing AtmosCare-KalpVriksha
            </h4>
            <h4 className="font-weight-bold">
              1000 Hectare bio-engineered forest in 1000 cu. meters
            </h4>
            <img src="About/clean1.jpg" width="100%" height="400px" className='hariram' alt="cleanair"></img>
            <p className="text-left mt-3">
              AtmosCare-KalpVriksha captures toxic pollutants and releases
              99.99% pure Himalayan quality air loaded with cosmic energy. Like
              any other natural tree, Kalpavriksha can absorb pollutants and
              convert it into biomass. Biomass can be converted to biofuels.
              Hence gaseous wastes can now be converted to clean fuel and
              cleanest air
            </p>
            <h4 className="font-weight-bold text-left mt-3">
              How AtmosCare-KalpVriksha purifies air?
            </h4>
            <p className="text-left mt-3">
              KalpVriksha uses Growdiesel proprietary technologies & catalysts
              to extract 99.99% pollutants from outdoor and release 30 cr.
              litres/day of purified ultraclean air. Dirty outdoor air enters
              AtmosCare-KalpVriksha which is a proprietary reactor with a
              reserve capacity of 600,000 litres. KalpVriksha generates
              electrostatic energy like a rainstorm to extract all pollutants
              from dirty air. The cleaned air is then purified using expensive
              catalysts which are more effective than catalytic-converters used
              in vehicles. The purified air then passed through ultraviolet
              germicidal irradiation in a fully enclosed air-tight chamber to
              kill air-borne infectious microbes, fungi, molds etc. The killed
              microbes are removed from air using specially designed
              nano-filters to release ultra-purified air. The ultra-purified air
              is finally enriched with OXYGEN using Sun’s energy and natural
              plants as bio-catalysts.
            </p>
            <h5 className="font-weight-bold text-left mt-3">
              1. Phase-I: Growdiesel Proprietary reactors:
            </h5>
            <p className="text-left mt-3">
              Human lung has a reserve capacity of 3 litres. Each
              AtmosCare-KalpVriksha has a reserve capacity of 600,000 litres.
            </p>
            <h5 className="font-weight-bold text-left mt-3">
              2. Phase-II: Growdiesel Proprietary electrostatic technology
              generators:
            </h5>
            <p className="text-left mt-3">
              KalpVriksha generates electrostatic energy like a rainstorm to
              extract all pollutants from dirty air.
            </p>
            <h5 className="font-weight-bold text-left mt-3">
              3. Phase-III: Growdiesel Proprietary photo-catalytic technology:
            </h5>
            <p className="text-left mt-3">
              The pollutants are converted to fuel producing biomass using Sun’s
              energy and bio-catalysts. ( Growdiesel Proprietary catalysts) 30
              cr. litres per day of dirty air is purified using expensive
              catalysts more effective than catalytic converters used in cars.
            </p>
            <h5 className="font-weight-bold text-left mt-3">
              4. Phase-IV: Growdiesel Proprietary germicidal technology:
            </h5>
            <p className="text-left mt-3">
              AtmosCareKalpVriksha uses ultraviolet germicidal irradiation in a
              fully enclosed airtight chamber to kill air-borne infectious
              microbes, fungi, molds etc and release ultra-purified air.
            </p>
            <h5 className="font-weight-bold text-left mt-3">
              5. Phase-V: Growdiesel Proprietary nano filtration technology:
            </h5>
            <p className="text-left mt-3">
              The killed microbes are removed from air using specially designed
              nano-filters.
            </p>
            <p className="text-left mt-3">
              The entire process operates like a clock so each KalpVriksha
              delivers purest Himalayan pine forest quality OUTDOOR air by
              removing 99.9% pollutants including PM1, PM2.5, PM10, carbon
              mono-oxide, ozone, cadmium, lead, nickel, sulphur dioxide,
              nitrogen dioxide, dust, pollen, mold, bacteria, pet dander and any
              other airborne pollutants.
            </p>
            <img src="About/clean2.jpg" width="100%" height="400px" className="hariram" alt="clean air 2"></img>
          </div>
          <div className="col-lg-3 ">
            <div className="my-5">
              <ul className="f1">
                <NavLink to="/" className="poco2">
                  <li className="point2 my-2 font-weight-bold">
                    INNOVATION
                </li>
                </NavLink>
                <NavLink to="/organic" className='poco'>
                  <li className="point my-2 font-weight-bold">
                    ORGANIC WASTE TO BIOFUEL
                </li>
                </NavLink>
                <NavLink to="/plastic" className='poco'>
                  <li className="point my-2 font-weight-bold">
                    {" "}
                    PLASTIC WASTE TO BIOFUEL
                </li>
                </NavLink>
                <NavLink to="/cleanair" className='poco'>
                  <li className="point my-2 font-weight-bold">
                    {" "}
                    KALPVRIKSHA (CLEAN AIR)
                </li>
                </NavLink>
                <NavLink to="/gets" className='poco'>
                  <li className="point my-2 font-weight-bold">
                    {" "}
                    GROWDIESEL ELECTRIC TRANSPORTATION SYSTEM
                </li>
                </NavLink>
              </ul>
            </div>
          </div>
        </div>
      </div>{" "}
      <div
        className="row container-fluid mt-5"
        style={{ backgroundColor: "#3ded97", padding: "20px", marginLeft: "0px" }}
      >
        <div className="col-lg-9">
          <h2 className="font-weight-bold minor">Start your own bio-refinery</h2>
          <h2 style={{ color: "darkblue", fontWeight: "bold" }} className="minor">
            GET Funding, Technology and Market Access.
          </h2>
        </div>
        <div className="col-lg-3">
          <a href="https://education.growdiesel.com/" target="_blank" rel="noreferrer"> <button className="lak font-weight-bold">
            <span>Know More</span>
          </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Cleanair;
