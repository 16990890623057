import React, { useEffect } from "react";
import ".././styles/Blogs.css";

function Blog() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <div className="header">
        <div className="text">
          <h1>Blogs</h1>
        </div>
      </div>
      <h1 className="heading mt-1">GET YOUR ARTICLE FEATURED</h1>
      <div>

        <div className="container osh">

          <div className="hariji">
            <div className="col-lg-4 col-sm-4 mt-3 keshu">
              <a href="http://blog.growdiesel.online/?p=20" target="_blank" className="sas" rel="noreferrer"><img src="https://images.unsplash.com/photo-1491947153227-33d59da6c448?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80" className="img-fluid sas" alt="growdiesel"></img></a>
            </div>
            <div className="col-lg-4 col-sm-4 mt-3 keshu">
              <a href="http://blog.growdiesel.online/?p=20" target="_blank" className="sas" rel="noreferrer"><img src="https://images.unsplash.com/photo-1495908333425-29a1e0918c5f?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80" className="img-fluid sas" alt="growdiesel2"></img></a>
            </div>
            <div className="col-lg-4 col-sm-4 mt-3 keshu">
              <a href="http://blog.growdiesel.online/?p=20" target="_blank" className="sas" rel="noreferrer"><img src="https://images.unsplash.com/photo-1444459094717-a39f1e3e0903?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80" className="img-fluid sas" alt="growdiesel3"></img></a>
            </div>



          </div>
          <div className="hariji">
            <div className="col-lg-4 col-sm-4 mt-3 keshu">
              <a href="http://blog.growdiesel.online/?p=20" target="_blank" className="sas" rel="noreferrer"><img src="https://images.unsplash.com/photo-1491947153227-33d59da6c448?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80" className="img-fluid sas" alt="growdiesel4"></img></a>
            </div>
            <div className="col-lg-4 col-sm-4 mt-3 keshu">
              <a href="http://blog.growdiesel.online/?p=20" target="_blank" className="sas" rel="noreferrer"><img src="https://images.unsplash.com/photo-1491947153227-33d59da6c448?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80" className="img-fluid sas" alt="growdiesel5"></img></a>
            </div>
            <div className="col-lg-4 col-sm-4 mt-3 keshu">
              <a href="http://blog.growdiesel.online/?p=20" target="_blank" className="sas" rel="noreferrer"><img src="https://images.unsplash.com/photo-1491947153227-33d59da6c448?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80" className="img-fluid sas" alt="growdiesel6"></img></a>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
