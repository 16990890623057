// import logo from "./logo.svg";
import "./styles/App.css";
import Home from "./Home";
// import { BrowserRouter } from "react-router-dom";
// import Menu from "./Menu";
import { Switch, Route, Redirect } from "react-router-dom";
import Story from "./About us/Story";
import Core from "./About us/Core";
import Journey from "./About us/Journey";
import Clients from "./About us/Clients";
import Organic from "./About us/Organic";
import Plasic from "./About us/Plasic";
import Gets from "./About us/Gets";
import Cleanair from "./About us/Cleanair";
import Projects from "./About us/Projectex";
import Trainings from "./About us/Trainings";
import MobileClinic from "./About us/MobileClinic";
import Sessions from "./About us/Sessions";
import Test from "./More/Test";
import Video from "./More/Video";
// import { Phone } from "@material-ui/icons";
import Photo from "./More/Photo";
import Opportunities from "./More/Opportunities";
import Online from "./More/Online";
import News from "./More/News";
import Contact from "./More/Contact";
import Offline from "./More/Offline";
import Blog from "./More/Blog";
import Footer from "./Footer";
import Maimenu from "./Mainmenu";
import Vendor from "./About us/Vendor";


function App() {
  return (
    <div className="App">
      <Maimenu className="fixed-top"></Maimenu>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/story" component={Story}></Route>
        <Route exact path="/team" component={Core}></Route>
        <Route exact path="/journey" component={Journey}></Route>
        <Route exact path="/clients" component={Clients}></Route>
        <Route exact path="/organic" component={Organic}></Route>
        <Route exact path="/plastic" component={Plasic}></Route>
        <Route exact path="/gets" component={Gets}></Route>
        <Route exact path="/cleanair" component={Cleanair}></Route>
        <Route exact path="/projects" component={Projects}></Route>
        <Route exact path="/trainings" component={Trainings}></Route>
        <Route exact path="/clinic" component={MobileClinic}></Route>
        <Route exact path="/sessions" component={Sessions}></Route>
        <Route exact path="/test" component={Test}></Route>
        <Route exact path="/videos" component={Video}></Route>
        <Route exact path="/photo" component={Photo}></Route>
        <Route exact path="/opportunites" component={Opportunities}></Route>
        <Route exact path="/online" component={Online}></Route>
        <Route exact path="/news" component={News}></Route>
        <Route exact path="/contact" component={Contact}></Route>
        <Route exact path="/offline" component={Offline}></Route>
        <Route exact path="/blog" component={Blog}></Route>
        <Route exact path="/vendor" component={Vendor}></Route>

        <Redirect to="/"></Redirect>
      </Switch>
      <Footer></Footer>

    </div>
  );
}

export default App;
