import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

function Plasic() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <div className="header">
        <div className="text">
          <h1 className="major">PLASTIC WASTE TO BIOFUEL / ENERGY</h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {/* hkkk */}
          <div className="col-lg-9 col-sm-12">
            <div className="row my-5 happen">
              <h3
                className="mb-3 mx-4 jack text-dark"
                style={{ textDecoration: "underline" }}
              >
                Plastic Waste To Biofuel
              </h3>
              <div className="hello mt-2">
                <a className="btn-success p-2" href="pdf/plastic-waste-to-biofuel.pdf" target="_blank">
                  <InsertDriveFileIcon className="mb-1" /> View/Download Company Profiles
                </a>
              </div>
            </div>
            {/* </div> */}
            <iframe
              width="100%"
              height="415"
              className="vansh"
              src="https://www.youtube.com/embed/dAII5rNgmNQ"
              frameborder="0"
              title="Youtube video main"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <h3 className="font-weight-bold mt-3 text-left">
              Thousands of tonnes of plastic is generated everyday
            </h3>
            <p className="text-left my-3">
              As per a government’s survey, a person living in an Indian city or
              village, on an average produces 300g to 1.2kg of waste every day.
              Most of this waste either goes to landfills, sewages or dumped on
              roads. The large landfills are a major source of air pollution.
              This also causes many deadly diseases. Talking about metropolitan
              cities like Ghaziabad, it alone generates around 900 Tonnes of
              waste daily, out of which 10%-15% is plastic waste. This causes
              immense pollution if not disposed properly.
            </p>

            <img src="About/2 (1).jpg" alt="plastic" width="100%" height="400px" className="hariram"></img>
            <p className="text-left my-3">
              Nature has shown us an excellent way of harvesting solar energy
              through biological route. Every living cell is an energy power
              house. Directly or indirectly, it uses solar energy. Every
              bio-molecule is loaded with energy. We use this energy to drive
              our lives. When plants make food for us either through their grain
              or fruit, they also generate a large quantum of energy in
              paraphernalia like leaves, fruit skins etc. This paraphernalia, if
              handled properly can provide us a viable option of harvesting that
              energy.
            </p>

            <img src="About/5 (1).jpg" alt="plastic" width="100%" height="400px" className="hariram"></img>
            <p className="text-left mt-3">
              Growdiesel Ventures Limited developed its WTG2XG technology
              (Growdiesel’s 1st Generation technology) to extract green diesel
              from the plastics. This route of harvesting energy through WTG2XG
              also ensures that we get a strong green-bitumen which can be used
              for road construction.
            </p>
            <img src="About/3 (1).jpg" alt="plastic" width="100%" height="400px" className="hariram"></img>
            <p className="text-left mt-3">
              The plastic is treated at high temperature and pressure after
              which it is converted into green crude oil by the use of
              catalysts. This green crude is further processed to make green
              diesel. This green diesel can be used to run vehicles, digisets,
              and generators. The by-product is green-bitumen which is used for
              road construction
            </p>
            <img src="About/4 (1).jpg" alt="plastic" width="100%" height="400px" className="hariram"></img>
            <p
              className="text-left mt-3 font-weight-bold "
              style={{ fontSize: "21px" }}
            >
              Growdiesel Ventures Limited is working towards a sustainable
              future for your children and generations to come.
            </p>
            <img src="About/serviceAreplastica.jpg" alt="plastic" width="100%" className="hariram"></img>
          </div>
          <div className="col-lg-3 ">
            <div className="my-5">
              <ul className="f1">
                <NavLink to="/" className="poco2">
                  <li className="point2 my-2 font-weight-bold">
                    INNOVATION
                </li>
                </NavLink>
                <NavLink to="/organic" className='poco'>
                  <li className="point my-2 font-weight-bold">
                    ORGANIC WASTE TO BIOFUEL
                </li>
                </NavLink>
                <NavLink to="/plastic" className='poco'>
                  <li className="point my-2 font-weight-bold">
                    {" "}
                    PLASTIC WASTE TO BIOFUEL
                </li>
                </NavLink>
                <NavLink to="/cleanair" className='poco'>
                  <li className="point my-2 font-weight-bold">
                    {" "}
                    KALPVRIKSHA (CLEAN AIR)
                </li>
                </NavLink>
                <NavLink to="/gets" className='poco'>
                  <li className="point my-2 font-weight-bold">
                    {" "}
                    GROWDIESEL ELECTRIC TRANSPORTATION SYSTEM
                </li>
                </NavLink>
              </ul>
            </div>
          </div>
        </div>
      </div>{" "}
      <div
        className="row container-fluid mt-5"
        style={{ backgroundColor: "#3ded97", padding: "20px", marginLeft: "0px" }}
      >
        <div className="col-lg-9">
          <h2 className="minor font-weight-bold">Start your own bio-refinery</h2>
          <h2 style={{ color: "darkblue", fontWeight: "bold" }} className="minor">Start your own green refinery</h2>
        </div>
        <div className="col-lg-3">
          <a href="https://education.growdiesel.com/" target="_blank" rel="noreferrer"> <button className="lak">
            <span>Know More</span>
          </button></a>
        </div>
      </div>
    </div>
  );
}

export default Plasic;
