import React, { useEffect } from "react";
import ".././styles/Photo.css";


function Photo() {
    useEffect(() => {
        var gallery = document.getElementById('gallery');
        // console.log("gallery", gallery)
        var getVal = function (elem, style) { return parseInt(window.getComputedStyle(elem).getPropertyValue(style)); };
        var getHeight = function (item) { return item.querySelector('.content').getBoundingClientRect().height; };
        var resizeAll = function () {
            var altura = getVal(gallery, 'grid-auto-rows');
            var gap = getVal(gallery, 'grid-row-gap');
            gallery.querySelectorAll('.gallery-item').forEach(function (item) {
                var el = item;
                el.style.gridRowEnd = "span " + Math.ceil((getHeight(item) + gap) / (altura + gap));
            });
        };

        var imgArr = gallery.getElementsByTagName('img');
        // console.log("img", imgArr);
        for (let i = 0; i < imgArr.length; i++) {
            imgArr[i].classList.add('byebye');
            if (imgArr[i].complete) {
                // console.log(imgArr[i].src);
            }
            else {
                imgArr[i].addEventListener('load', function () {
                    var altura = getVal(gallery, 'grid-auto-rows');
                    var gap = getVal(gallery, 'grid-row-gap');
                    var gitem = imgArr[i].parentElement.parentElement;
                    gitem.style.gridRowEnd = "span " + Math.ceil((getHeight(gitem) + gap) / (altura + gap));
                    imgArr[i].classList.remove('byebye');
                });
            }
        }
        window.addEventListener('resize', resizeAll);
        gallery.querySelectorAll('.gallery-item').forEach(function (item) {
            item.addEventListener('click', function () {
                item.classList.toggle('full');
            });
        });


    }, [])


    return (
        <div>
            <div className="header">
                <div className="text">
                    <h1>PHOTOS</h1>
                </div>
            </div>
            <div>
                <div class="gallery" id="gallery">
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/event11.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/event1.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/event2.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/event3.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/event4.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/event5.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/event6.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/event7.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div className="content"><img src="gallery/event8.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/event9.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/event10.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha2.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha3.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha4.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha5.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha6.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha7.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha8.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha9.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha10.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha11.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha12.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha13.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha14.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha15.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha16.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha17.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha18.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha19.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/ha20.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/kar1.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/kar5.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/kar6.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/kar2.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/kar3.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/kar4.jpeg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/1.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/7.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/3.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/4.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/5.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/6.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/13.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/14.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/15.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/21.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/22.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/23.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/24.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/25.JPG" alt="img" /></div>
                    </div>
                    

                    <div class="gallery-item">
                        <div class="content"><img src="new/16.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/17.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/18.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/19.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/2.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/3-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/8.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/9.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/10.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/11.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/12.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/26.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/27.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/28.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/29.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/31.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/32.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/33.JPG" alt="img" /></div>
                    </div>
                    
                    <div class="gallery-item">
                        <div class="content"><img src="new/8.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/4-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/5-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/34.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/35.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/38.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/36.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="new/37.JPG" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/4-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/5-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/6-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/7-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/8-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/9-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/10-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/11-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/12-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/13-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/14-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/15-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/16-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/17-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/18-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/19-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/20-min.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/21.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/22.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/23.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/24.jpg" alt="img" /></div>
                    </div>

                    <div class="gallery-item">
                        <div class="content"><img src="gallery/25.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/27.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/28.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/29.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/30.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/31.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/32.jpg" alt="img" /></div>
                    </div>
                    <div class="gallery-item">
                        <div class="content"><img src="gallery/33.jpg" alt="img" /></div>
                    </div>

                    <div class="gallery-item">
                        <div class="content"><img src="gallery/26.jpg" alt="img" /></div>
                    </div>
                    
                </div>

            </div>
        </div>
    );
}

export default Photo;
