import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./styles/Testominals.css";

function Testomonials() {
  return (
    <div>
      <div className="my-5">
        <h1 className="text-center heading my-4">TESTIMONIALS</h1>
        <Carousel class="addpad" id="addpad2">
          <Carousel.Item>
            <div className="conatiner-fluid mx-auto">
              <div className="row container-fluid mx-auto">
                <div className="col-lg-4 col-sm-12 ">
                  <div className="card back shadow">
                    <img
                      src="Testo/1. Sheila Dikshit.jpg"
                      alt="img"
                      className="img-fluid testo"
                      
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname">Smt. Sheila Dikshit</h4>
                      <p style={{ fontWeight: "600" }}>
                        Former Chief Minister, Delhi
                      </p>
                      <p className="card-title  mb-4">
                        "I was always fascinated by the idea of eliminating
                        waste. After inaugurating Growdiesel’s waste to biofuel
                        plant, I am 100% convinced that future will be about
                        clean fuels and energy. The best part about Growdiesel’s
                        plant is that there is absolutely no bad smell and the
                        premises are very hygienic.”
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Testo/2. James Sangma.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname">Mr. James Sangma</h4>
                      <p style={{ fontWeight: "600" }}>
                        Power Minister, Meghalaya
                      </p>
                      <p className="card-title  mb-4">
                        "Being a power minister, it is my duty to explore and
                        execute projects that involve clean energy. I visited
                        Growdiesel’s plant and was stunned to see a professional
                        operation. It’s great to see such initiative of
                        converting waste into energy and we will begin
                        replicating this project wherever possible.”
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Testo/3. Montek Singh.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname">
                        Mr. Montek Singh Ahluwalia
                      </h4>
                      <p style={{ fontWeight: "600" }}>
                        Former Deputy Chairman, Planning<br></br>
                        Commission (Now Niti Aayog)
                      </p>
                      <p className="card-title  mb-4">
                        “Very innovative and outstanding work done by the entire
                        team of Growdiesel. This technology has a great
                        potential to change millions of lives. Commendable
                        work.”
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="conatiner-fluid">
              <div className="row container-fluid mx-auto">
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Testo/4. Shamlal Goyal.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname">
                        Dr. Shamlal Goyal, I.A.S.
                      </h4>
                      <p style={{ fontWeight: "600" }}>
                        Adl. Chief Secretary of Maharashtra
                      </p>
                      <p className="card-title  mb-4">
                        “I had an opportunity to visit Growdiesel’s facilities
                        with my entire team of 10 engineers. I really appreciate
                        the hard work and innovation put behind this technology.
                        This must be replicated everywhere in the country for a
                        cleaner and greener future.”
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Testo/5. Anil Kapoor.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname">Lt. Gen. Anil Kapoor</h4>
                      <p style={{ fontWeight: "600" }}>
                        Director General, Indian Army EME
                      </p>
                      <p className="card-title  mb-4">
                        “Profound to see such a systematic operation of
                        converting waste into fuel. Very professionally arranged
                        site visit. Way to go. All the best.”
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Testo/6. Vijay Dev.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname">
                        Sh. Vijay Kumar Dev, I.A.S.
                      </h4>
                      <p style={{ fontWeight: "600" }}>
                        Chief Secretary of Delhi
                      </p>
                      <p className="card-title  mb-4">
                        “The organisation has very special skill of delivering
                        projects on time. Their efforts and energy are truly
                        appreciated by the government of Delhi.”
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="conatiner-fluid ">
              <div className="row container-fluid mx-auto">
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Testo/7. Nishant Yadav.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname">
                        Mr. Nishant Yadav, I.A.S.
                      </h4>
                      <p style={{ fontWeight: "600" }}>
                        Deputy Commissioner, Karnal, Haryana
                      </p>
                      <p className="card-title  mb-4">
                        “The waste to biofuel project has been a pride of the
                        chief Minister’s city, Karnal ever since its operations
                        began. I was extremely happy to see smooth and effective
                        working of the project. More such projects throughout
                        India will enable to eliminate waste and generate clean
                        energy.”
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Testo/8. Surbhi Mittal.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname">Mrs. Surbhi Mittal</h4>
                      <p style={{ fontWeight: "600" }}>
                        Director, Sonalika Tractors CSR
                      </p>
                      <p className="card-title  mb-4">
                        “Sonalika CSR was extremely happy to be a part of
                        Growdiesel’s project. The execution was delightful and
                        with utmost precision. We hope that our contribution
                        brought a great impact to the society.”
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Testo/9. Dr. BM Mishra.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname">
                        Dr. B.M. Mishra, I.A.S.
                      </h4>
                      <p style={{ fontWeight: "600" }}>
                        District Magistrate, Delhi (South)
                      </p>
                      <p className="card-title  mb-4">
                        “Growdiesel helped the district administration in
                        executing state of the art Corona Testing Mobile Clinics
                        for the benefit of the citizens of the city. Despite
                        being a state of lockdown, Growdiesel executed the
                        project extremely fast. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="conatiner-fluid ">
              <div className="row container-fluid mx-auto">
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Testo/10. Dr. Priyanka Soni.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname">
                        Dr. Priyanka Soni, I.A.S.
                      </h4>
                      <p style={{ fontWeight: "600" }}>
                        Former Municipal Commissioner, Karnal, Haryana
                      </p>
                      <p className="card-title  mb-4">
                        “Waste to biofuel is a concept that is the need of the
                        hour. Growdiesel has showcased a successful execution
                        and more such projects are definitely going to be
                        implemented for the benefit of the society.”
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Testo/11. Dr. Sharad Kale.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname">Dr. Sharad Kale</h4>
                      <p style={{ fontWeight: "600" }}>
                        Baba Atomic Research Centre
                      </p>
                      <p className="card-title  mb-4">
                        “Baba Atomic Research Centre has been particularly
                        impressed by the technology developed by Growdiesel. It
                        is an extremely systematic procedure they have adopted
                        by developing a bioreactor based infra.”
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Testo/12. Sonalika Jewani.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname">
                        Ms. Sonalika Jewani, I.A.S.
                      </h4>
                      <p style={{ fontWeight: "600" }}>
                        Sub District Magistrate, Delhi
                      </p>
                      <p className="card-title  mb-4">
                        “The district administration sincerely appreciates the
                        efforts of Growdiesel for developing and delivering an
                        innovative solution to conduct faster corona testing at
                        doorsteps of Delhi at ZERO cost to government and
                        public. They have an excellent team who can solve great
                        problems.”
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="conatiner-fluid ">
              <div className="row container-fluid mx-auto">
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Testo/13. Dr. MC Mishra.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname" >Dr. M.C. Mishra</h4>
                      <p style={{ fontWeight: "600" }}>
                        Former Director, A.I.I.M.S. Delhi
                      </p>
                      <p className="card-title  mb-4">
                        “AIIMS Delhi was really concerned about the growing
                        pollution in Delhi and Growdiesel came up with an R&D
                        project which was executed at AIIMS Delhi at ZERO cost
                        to government.”
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Testo/14. Mukul Rohatgi.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname">Mr. Mukul Rohatgi</h4>
                      <p style={{ fontWeight: "600" }}>
                        Former Attorney General of India
                      </p>
                      <p className="card-title  mb-4">
                        “Being a socially responsible citizen is everyone’s duty
                        and everyone should do their bit. I was happy to do my
                        bit in donating a corona testing mobile clinic to the
                        Delhi government. The project was executed by Growdiesel
                        and the project helped thousands of people.”
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Testo/15. Aditya Dhaiya.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname">
                        Dr. Aditya Dahiya, I.A.S.
                      </h4>
                      <p style={{ fontWeight: "600" }}>
                        Former Deputy Commissioner, Karnal, Haryana
                      </p>
                      <p className="card-title  mb-4">
                        “This approach of converting paddy stubble to bio-CNG
                        adopted by the administration is one of the most
                        innovative initiatives. The organisation has done a good
                        job in delivering and operating the project.”
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}
export default Testomonials;
