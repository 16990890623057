import React, { useEffect } from "react";
import ".././styles/Story.css";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

function Story() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div  >
      <div className="header">
        <div className="text">
          <h1 className="major">OUR ‘green’ STORY</h1>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="content col-lg-6 md-6 col-sm-12 text-left">
            <div className="row my-3 box happen">
              <h2
                className="mb-3 mx-4 jack text-dark"
                style={{ textDecoration: "underline" }}
              >
                Company Brief
              </h2>

              <div className="hello mt-2">
                <a className="btn-success p-2" href="pdf/COMPANY- PROFILE.pdf" target="_blank">
                  <InsertDriveFileIcon className="mb-1" /> View/Download Company Profiles
                </a>
              </div>
            </div>
            {/* <hr className="bg-success  text-left"></hr> */}

            <p className="ishu">
              <span className="font-weight-bold"> Growdiesel</span> is India’s
              No.1 renewable oil & gas exploration and production organization
              dedicated to the cause of environment, energy and empowerment of
              fuel farmers. Growdiesel’s core business is to produce and sell
              renewable cleanfuels.
            </p>
            <p className="ishu">
              <span className="font-weight-bold"> Growdiesel</span> was founded
              with a belief that it is possible to transform the future, to take
              steps and make differences to the lives of millions of people. In
              line with this core belief, Growdiesel remains focused to develop
              businesses with ‘triple bottom line’ addressing the aspects of
              economic, social and environmental benefits. Petroleum gets formed
              by the compression of fossil and all types of organic matter like
              algae/ biomass/waste over millions of years.
            </p>
            <p className="ishu">
              <span className="font-weight-bold"> Growdiesel</span> has
              developed cutting edge technologies, equipment 3 and catalysts to
              do it in minutes.
            </p>
          </div>
          <div className="video col-lg-6 md-6 col-sm-12 mt-5">
            <iframe
              width="100%"
              height="350"
              className="vansh"
              src="https://www.youtube.com/embed/BtB6H15FIW8"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              title="video main"
            ></iframe>
          </div>
        </div>
        <p className="text-left mt-1n ishu">
          <span className="font-weight-bold"> Growdiesel</span> is now
          establishing modern manufacturing facilities to produce advanced
          renewable cleanfuels known as Growdiesel NextGenFuels™. These include
          BioCNG (a substitute for petrol and CNG), green premium diesel (a
          substitute for conventional diesel) and green Aviation Turbine Fuel (a
          substitute for petroleum jet fuel) The biggest USP of Growdiesel
          NextGenFuels™ is that they are Drop-in Fuels it means they can replace
          fossil fuels in existing engines, vehicles, equipments and entire
          existing petroleum infrastructure without any modifications. Use of
          Growdiesel NextGenFuels™ signifies great savings in fresh investments
          in designing/developing new engines/infrastructure. Growdiesel has
          Research & Manufacturing Facilities in India. Our specialization lies
          in developing high-risk ideas into high-value businesses. We achieve
          this goal through intensive research leading to fabrication of small
          manufacturing plants and extensively working on such plants. This
          approach gives us valuable real life experience. Armed with this
          practical knowledge we move towards commercialization by undertaking
          project development over its entire life cycle.
        </p>
        <p className="text-left ishu">
          Two laws govern the Mother Earth. They are-law of conservation of
          matter and law of conservation of energy. Matter and energy can
          neither be created nor destroyed. They only change through various
          forms. The life cycle is thus an integration of various elemental
          cycles. It is important that all these cycles must continue in an
          undisturbed fashion to maintain the Nature’s Cycle.
        </p>
        <p className="text-left ishu">
          We must look at every material as a potential resource for fuel. The
          concept of waste therefore needs to be revamped in totality. In fact
          there is no waste generated in Nature. Every component that is
          generated is a part of some cycle. Hence each waste should be reused.
          We must change the name of Waste BIN to Resource Bin. This will help
          to change the mental attitude. At present all type of waste is
          disposed off directly at nearby collection spots.
        </p>
        <p className="text-left ishu">
          The substantial quantity of farm waste/cattle waste is used in
          undigested manner for cultivation of vegetables/crops etc. It is a
          major cause of pathogens getting into food chain & causing wide spread
          diseases. As per our observations, around 90 to 95% of waste generated
          by such farms is biodegradable and can be effectively converted to
          renewable oil and gas using advanced technologies being developed by
          Growdiesel.
        </p>
        <div className="row mt-5">
          <div className="col-lg-6 md-6 col-sm-12">
            <img src="images/BusinessModel.jpg" width="100%" className="img-fluid" alt="bussiness" />
          </div>
          <div className="col-lg-6 md-6 col-sm-12">
            <h2 className="text-left  mb-3 font-weight-bold kashmir" style={{ textDecoration: "underline" }}>
              Growdiesel - The Way Forward:
            </h2>
            <p className="text-left ishu">
              <span className="font-weight-bold ishu"> Growdiesel</span> When we look
              back we feel elated by our contribution in development of Indian
              Biofuel Sector. Though the industry is still in its infancy, we
              feel proud at the way we have travelled overcoming all types of
              technological, marketing and financial challenges. We have sailed
              through difficult times 8 due to dedicated efforts of our
              passionate team. Over the past years, we have infused millions of
              dollars in biofuel project developmental activities. We feel happy
              to state that we have travelled so far without any external equity
              or debt support.
            </p>
          </div>
          <p className="text-left mt-2  kalu ishu">
            Industry watchers admit that the progress that Growdiesel has made
            with its own resources is really splendid. As per projections by
            DOE, USA, the Global clean fuel business is pegged at US$ four
            trillion by the year 2030. The Indian market is growing at over 7%
            per anum. Growdiesel is poised to be a leading global player with a
            substantial market share by 2030.
          </p>
        </div>
      </div>
      <div
        className="row container-fluid mt-5"
        style={{ backgroundColor: "#3ded97", padding: "20px", marginLeft: "0px" }}
      >
        <div className="col-lg-9">
          <h2 className="font-weight-bold minor">Start a business or job in green energy sector</h2>
          <h2 style={{ color: "darkblue", fontWeight: "bold" }} className="minor">
            GET Funding, Technology and Market Access.
          </h2>
        </div>
        <div className="col-lg-3">
          <a href="https://growdiesel.in/webinar/" target="_blank" rel="noreferrer" > <button className="lak font-weight-bold">
            <span>Know More</span>
          </button></a>
        </div>
      </div>
    </div>
  );
}
export default Story;
