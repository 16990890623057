import React,{useEffect} from "react";

function Projectex() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <div className="header">
        <div className="text">
          <h1 className="major">PROJECTS</h1>
        </div>
      </div>
    </div>





  );
}

export default Projectex;
