import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./styles/Initative.css";

function Initative() {
  return (
    <div className="my-5">
      <h1 className="heading mb-2">INITIATIVES FOR ENVIRONMENT AND SOCIETY</h1>
      <Carousel>
        <Carousel.Item>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 rubber">
                <div className="card init">
                  <img
                    src="ini/1. Medical testing mobile unit.jpg"
                    alt="me"
                    className="img-fluid greenut"
                  />
                  <h4 className="mb-2 head">MEDICAL TESTING MOBILE CLINIC</h4>
                  <p className="mx-3 written">
                    Health infrastructure in India still needs a lot of
                    development. The idea of mobile medical unit is something
                    that is gaining a lot of attraction. After delivering
                    several such clinics to the government in association with
                    CSR partners and individuals, the initiative won a SKOCH
                    award. We can use this medical unit in various ways.
                  </p>
                  <button className="donate card-footer">
                    Donate mobile clinics
                  </button>
                </div>
              </div>
              <div className="col-lg-6 rubber">
                <div className="card init">
                  <img
                    src="ini/2. Rickshaw Donation.jpg"
                    alt="rickshaw"
                    className="img-fluid greenut"
                  />
                  <h4 className="mb-2 head">
                    Rickshaw donation to rag-pickers
                  </h4>
                  <p className="mx-3 written">
                    We at Growdiesel believe that rag picker’s community is
                    important part of waste elimination. In order to carry
                    out their job effectively, we provide them with a custom
                    electric/manual rickshaw that eases their work efficiency.
                    The rickshaw has a special design with different
                    compartments for different types of waste.
                  </p>
                  <button className="donate card-footer">
                    Donate Rickshaw's
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 rubber">
                <div className="card init">
                  <img
                    src="ini/3. Hero cycles and Growdiesel.jpg"
                    alt="hero"
                    className="img-fluid greenut"
                  />
                  <h4 className="mb-2 head">
                    KALPAVRISKHA – Outdoor air purification
                  </h4>
                  <p className="mx-3 written">
                    Growdiesel in association with All India Institute of
                    Medical Science (AIIMS Delhi) commissioned an outdoor air
                    purification project named ‘kalpavriksha’. <br></br>1
                    Growdiesel KalpaVriksha = 50000 full grown trees
                  </p>
                  <button className="donate">Donate a KalpaVirksha</button>
                </div>
              </div>

              <div className="col-lg-6 rubber">
                <div className="card init">
                  <img
                    src="ini/4. Community Plantation.jpg"
                    alt="community"
                    className="img-fluid greenut"
                  />
                  <h4 className="mb-2 head">COMMUNITY PLANTATION DRIVES</h4>
                  <p className="mx-3 written">
                    We at Growdiesel keep organizing various community
                    plantation drives to boost the greenery in several areas.
                    Our volunteers help the local residents by educating them
                    about plantation and good horticulture practices.
                  </p>
                  {/* <footer className="card-footer "> */}
                  <button className="donate card-footer w-100">
                    Sponsor a similar initiative
                  </button>
                  {/* </footer> */}
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 rubber">
                <div className="card init">
                  <img
                    src="ini/5. Street Plays.jpg"
                    alt="street playes"
                    className="img-fluid greenut"
                  />
                  <h4 className="mb-2 head">STREET PLAYS FOR AWARENESS</h4>
                  <p className="mx-3 written">
                    Growdiesel believes in connecting with the youth in creative
                    ways. Hence, we occasionally organize street plays to
                    educate youth as well as the adults about the importance of
                    sustainable practices.
                  </p>
                  <button className="donate">
                    Sponsor a similar initiative
                  </button>
                </div>
              </div>
              <div className="col-lg-6 rubber">
                <div className="card init">
                  <img
                    src="ini/6. Community walks.jpg"
                    alt="community walks"
                    className="img-fluid greenut"
                  />
                  <h4 className="mb-2 head">COMMUNITY WALK FOR GREEN LIFE</h4>
                  <p className="mx-3 written">
                    Growdiesel believes that it is extremely important to focus
                    on the community and hence we keep organizing 
                    walks in order to spread the message of a sustainable
                    living in our area.
                  </p>
                  <button className="donate">
                    Sponsor a similar initiative
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 rubber">
                <div className="card init">
                  <img
                    src="ini/7. Collaboration with schools.jpg"
                    alt="collabroation"
                    className="img-fluid greenut"
                  />
                  <h4 className="mb-2 head">COLLABORATION WITH SCHOOLS</h4>
                  <p className="mx-3 written">
                    Growdiesel in association with schools organizes different
                    activities in order to educate children about climate
                    change. We also focus on connecting with the students
                    through creative initiatives such as art competitions, magic
                    shows, quizzes, debates and much more.
                  </p>
                  <button className="donate">
                    Sponsor a similar initiative
                  </button>
                </div>
              </div>
              <div className="col-lg-6 rubber">
                <div className="card init">
                  <img
                    src="ini/8. Outdoor air purification.jpg"
                    alt="outdoors"
                    className="img-fluid greenut"
                  />
                  <h4 className="mb-2 head ">
                    GROWDIESEL AND HERO CYCLES JOIN HANDS
                  </h4>
                  <p className="mx-3 written">
                    Growdiesel in association with HERO cycles educated 2000+
                    students in over 20 villages of Himachal Pradesh about
                    cleanliness. We also distributed sustainable stationary
                    products made out of recycled material. The event was
                    attended by volunteers from 10+ countries
                  </p>
                  <button className="donate card-footer">
                    Sponsor a similar initiative
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 rubber">
                <div className="card init">
                  <img
                    src="ini/9. Mobile Games.jpg"
                    alt="mobile games"
                    className="img-fluid greenut"
                  />
                  <h4 className="mb-2 head">MOBILE GAMES</h4>
                  <p className="mx-3 written">
                    We at Growdiesel continuously evolve and lookout for
                    innovative ways to spread our mission of fighting climate
                    change. We have developed a mobile game named ‘Green Runner’
                    for the same. We will continue to develop more such games.
                  </p>
                  <button className="donate">Download Now</button>
                </div>
              </div>
              <div className="col-lg-6 rubber">
                <div className="card init">
                  <img
                    src="ini/10. Travel companies.jpg"
                    alt="travel companies"
                    className="img-fluid greenut"
                  />
                  <h4 className="mb-2 head">
                    Collaborations with travel companies
                  </h4>
                  <p className="mx-3 written">
                    Growdiesel has developed certain travel products made of
                    recycled/reusable material in order to promote sustainable
                    travel habits. We have collaborated with few travel
                    companies and looking for more such associations.
                  </p>
                  <button className="donate">Get Associated</button>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
export default Initative;
