import React, { useEffect } from "react";
import ".././styles/Organic.css";
import { NavLink } from "react-router-dom";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

function Organic() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <div className="header">
        <div className="text">
          <h1 className="major">ORGANIC WASTE TO BIOFUEL / ENERGY</h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {/* hkkk */}
          <div className="col-lg-9 col-sm-12">
            <div className="row my-5 happen">
              <h3
                className="mb-3 mx-4 jack text-dark"
                style={{ textDecoration: "underline" }}
              >
                Company Brief
              </h3>
              <div className="hello mt-2">
                <a className="btn-success p-2" href="pdf/organic_waste_to_biofuel.pdf" target="_blank">
                  <InsertDriveFileIcon className="mb-1" /> View/Download Company Profiles
                </a>
              </div>
            </div>
            {/* </div> */}
            <iframe
              width="100%"
              height="415"
              title="Bio waste Fuel"
              className="vansh"
              src="https://www.organicsyoutube.com/embed/dAII5rNgmNQ"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <p className="text-left my-3">
              <span className="font-weight-bold">Millions</span> of tonnes of
              waste is generated every day. As per a government’s survey, a
              person living in an Indian city or village, on an average produces
              300g to 1.2kg of waste every day. Most of this waste either goes
              to landfills, sewages or dumped on roads. The large landfills are
              a major source of air pollution. This also causes many deadly
              diseases. Talking about capital of India, Delhi, it alone
              generates 10,000 Metric Tons of waste per day. Out of which,
              60%-70% is organic/kitchen waste. Total waste generated in India
              is around 2 lakh tonnes (as of 2017)
            </p>

            <img  src="About/1.jpg" alt="organics" width="100%" height="400px" className="hariram"></img> 
            <p className="text-left my-3">
              While it is termed as “waste”, we need to understand that
              someone’s waste is other person’s “resource”. We at Growdiesel
              believe that it is possible to utilise this potential resource.
            </p>
            <p className="text-left">
              Nature has shown us an excellent way of harvesting solar energy
              through biological route. Every living cell is an energy power
              house. Directly or indirectly, it uses solar energy. Every
              bio-molecule is loaded with energy. We use this energy to drive
              our lives. When plants make food for us either through their grain
              or fruit, they also generate a large quantum of energy in
              paraphernalia like leaves, fruit skins etc. This paraphernalia, if
              handled properly can provide us a viable option of harvesting that
              energy.
            </p>
            <img  src="About/2.jpg" alt="organics" width="100%" height="400px" className="hariram"></img> 
            <p className="text-left mt-3">
              Growdiesel Ventures Limited developed its{" "}
              <span className="font-weight-bold">WTG1XG technology</span>
              (Growdiesel’s 1st Generation) to extract renewable natural gas
              (rNG)TM from the biomass. This route of harvesting energy through
              WTG1XG also ensures that we get an energy rich soil conditioner.
            </p>
            <img  src="About/3.jpg" alt="organics" width="100%" height="400px" className="hariram"></img> 
            <p className="text-left mt-3">
              WTG1XG process is carried out by combining four stages of
              microbial degradation of the biodegradable wastes in four
              independent scientifically designed proprietary bio-reactors, each
              loaded with separate set of Growdiesel’s proprietary bio-catalysts
              functioning under different temperature and pH conditions.
            </p>
            <img  src="About/4.jpg" alt="organics" width="100%" height="400px" className="hariram"></img> 
            <p className="text-left mt-3">
              After this 4 stage process, the (rNG)TM is extracted by
              Growdiesel’s WTG1XG technology and can be used as bio-PNG (Piped
              natural Gas) for cooking, bio-CNG (Compressed Natural gas) for
              filling vehicles or to generate green electricity. The by-product
              is bio-fertilizer which is used for horticulture or organic
              farming.
            </p>
            <img  src="About/5.jpg" alt="organics" width="100%" height="400px" className="hariram"></img> 
            <p className="text-left mt-3">
              Growdiesel Ventures Limited is working towards a sustainable
              future for your children and generations to come.
            </p>
            <div className="my-4">
              <h4 className="text-left">Want to associate with Growdiesel?</h4>
              <div className="text-left">
                <button className="btn-success">Know More</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 ">
            <div className="my-5">
              <ul className="f1">
                <NavLink to="/" className="poco2">
                  <li className="point2 my-2 font-weight-bold">
                    INNOVATION
                </li>
                </NavLink>
                <NavLink to="/organic" className='poco'>
                  <li className="point my-2 font-weight-bold">
                    ORGANIC WASTE TO BIOFUEL
                </li>
                </NavLink>
                <NavLink to="/plastic" className='poco'>
                  <li className="point my-2 font-weight-bold">
                    {" "}
                    PLASTIC WASTE TO BIOFUEL
                </li>
                </NavLink>
                <NavLink to="/cleanair" className='poco'>
                  <li className="point my-2 font-weight-bold">
                    {" "}
                    KALPVRIKSHA (CLEAN AIR)
                </li>
                </NavLink>
                <NavLink to="/gets" className='poco'>
                  <li className="point my-2 font-weight-bold">
                    {" "}
                    GROWDIESEL ELECTRIC TRANSPORTATION SYSTEM
                </li>
                </NavLink>
              </ul>
            </div>
          </div>
        </div>
      </div>{" "}
      <div
        className="row container-fluid mt-5 font-weight-bold"
        style={{ backgroundColor: "#3ded97", padding: "20px", marginLeft: "0px" }}
      >
        <div className="col-lg-9">
          <h2 className="font-weight-bold minor">Start your own bio-refinery</h2>
          <h2 style={{ color: "darkblue", fontWeight: "bold" }} className="minor">
            GET Funding, Technology and Market Access.
          </h2>
        </div>
        <div className="col-lg-3">
          <a href="https://education.growdiesel.com/" target="_blank" rel="noreferrer"> <button className="lak font-weight-bold">
            <span>Know More</span>
          </button></a>
        </div>
      </div>
    </div>
  );
}

export default Organic;
