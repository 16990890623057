import React from "react";
import ".././styles/Opport.css";

function Opportunities() {
  return (
    <div>
      <div className="header">
        <div className="text">
          <h1>‘green’ OPPORTUNITIES</h1>
        </div>
      </div>
      <div className="container my-4">
        <div className="card" style={{ border: "1px solid black" }}>
          <h2 className="headingsml mb-0">Channel Partnership</h2>
          <img
            alt="growdiesel"
            src="https://images.unsplash.com/photo-1463320898484-cdee8141c787?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
            width="100%"
            height="300px"
            // alt="channel patnertship"
          ></img>

          <p
            className="text-left my-2"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              fontSize: "17px",
            }}
          >
            Start your own company in the biofuel sector with Growdiesel as your
            partner. Establish a bio-refinery with or without your investment.
            Government of India through its policies is aggressively pushing the
            industry and this is an excellent time to be a part of it.
          </p>
          <p
            className="text-left my-2"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              fontSize: "17px",
            }}
          >
            {" "}
            To know more, attend our Waste 2 Wealth Masterclass by subject
            matter experts of Growdiesel, Stanford University, IIM Ahmedabad,
            ISB Hyderabad, IIT Delhi, Rank holder Chartered Accountant (CA) and
            Chartered Financial Analyst (CFA).
          </p>
          <button className="w-100 btn-success fruit">Learn More</button>
        </div>
      </div>
      <div className="container my-4">
        <div className="card" style={{ border: "1px solid black" }}>
          <h2 className="headingsml mb-0">Independent Business Consultant</h2>
          <img
            src="https://images.unsplash.com/photo-1463320898484-cdee8141c787?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
            width="100%"
            height="300px"
            alt="background"
          ></img>

          <p
            className="text-left my-2"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              fontSize: "17px",
            }}
          >
            Want to be a part of this industry but only part time? Don’t worry,
            we have an opportunity for you where you can generate a part time
            income.
          </p>
          <p
            className="text-left my-2"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              fontSize: "17px",
            }}
          >
            {" "}
            To know more, attend our Waste 2 Wealth Masterclass by subject
            matter experts of Growdiesel, Stanford University, IIM Ahmedabad,
            ISB Hyderabad, IIT Delhi, Rank holder Chartered Accountant (CA) and
            Chartered Financial Analyst (CFA).
          </p>
          <button className="w-100 btn-success fruit">Learn More</button>
        </div>
      </div>
      <div className="container my-4">
        <div className="card" style={{ border: "1px solid black" }}>
          <h2 className="headingsml mb-0">
            Growdiesel Opportunity Accelerator
          </h2>
          <img
            src="https://images.unsplash.com/photo-1463320898484-cdee8141c787?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
            width="100%"
            height="300px"
            alt="Growdiesel Opportunity Accelerator"
          ></img>

          <p
            className="text-left my-2"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              fontSize: "17px",
            }}
          >
            Are you a student who wants to have a startup in green energy
            sector? Growdiesel is giving an exclusive opportunity to students
            who are passionate and want to get technology, funding and market
            access to kick-start their journey of ‘green’ entrepreneurship.
          </p>
          <p
            className="text-left my-2"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              fontSize: "17px",
            }}
          >
            {" "}
            To know more, attend our Waste 2 Wealth Masterclass by subject
            matter experts of Growdiesel, Stanford University, IIM Ahmedabad,
            ISB Hyderabad, IIT Delhi, Rank holder Chartered Accountant (CA) and
            Chartered Financial Analyst (CFA).
          </p>
          <button className="w-100 btn-success fruit">Learn More</button>
        </div>
      </div>
      <div className="container my-4">
        <div className="card" style={{ border: "1px solid black" }}>
          <h2 className="headingsml mb-0">Become our vendor</h2>
          <img
            src="https://images.unsplash.com/photo-1463320898484-cdee8141c787?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
            width="100%"
            height="300px"
            alt="Become our vendor"
          ></img>

          <p
            className="text-left my-2"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              fontSize: "17px",
            }}
          >
            Are you service or product provider? Become Growdiesel’s empanelled
            vendor and start supplying us your services or products today.
          </p>
          <p
            className="text-left my-2"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              fontSize: "17px",
            }}
          >
            {" "}
            Growdiesel only empanels vendors are proper due diligence and all
            vendors have to adhere to the procurement policy. Click Here to
            download the procurement policy.
          </p>
          <button className="w-100 btn-success fruit">Application Form</button>
        </div>
      </div>
      <div className="container my-4">
        <div className="card" style={{ border: "1px solid black" }}>
          <h2 className="headingsml mb-0">Work with us</h2>
          <img
            src="https://images.unsplash.com/photo-1463320898484-cdee8141c787?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
            width="100%"
            height="300px"
            alt="work with us"
          ></img>

          <p
            className="text-left my-2"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              fontSize: "17px",
            }}
          >
            At Growdiesel, we hire the most innovative minds we come across. The
            most important attribute we are looking for in a candidate is
            problem solving attitude.
          </p>
          <p
            className="text-left my-2"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              fontSize: "17px",
            }}
          >
            {" "}
            Do not send us the same resume which sent to hundreds of companies.
            Mention about the hardest problems you have worked on and how you
            solved them.
          </p>
          <button className="w-100 btn-success fruit">Apply right now</button>
        </div>
      </div>
      <div className="container my-4">
        <div className="card" style={{ border: "1px solid black" }}>
          <h2 className="headingsml mb-0">Volunteer</h2>
          <img
            src="https://images.unsplash.com/photo-1463320898484-cdee8141c787?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
            width="100%"
            height="300px"
            alt="volunteer"
          ></img>

          <p
            className="text-left my-2"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              fontSize: "17px",
            }}
          >
            At Growdiesel, we are continuously working for the betterment of
            environment and society. If you are someone who genuinely wants to
            volunteer and make a difference through actions, we need you.
          </p>

          <button className="w-100 btn-success fruit">Volunteer Now</button>
        </div>
      </div>
    </div>
  );
}

export default Opportunities;
