import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/Footer.css";
import { NavLink, Link } from "react-router-dom";

import SendIcon from "@material-ui/icons/Send";

function Footer() {
  return (
    <div>
      <footer>
        <div className="row">
          <div className="col-lg-4 col-sm-12">
            <img src="About/logob (1).png" className="mb-3 text-left" alt="logo"></img>
            <p className="text-white " style={{ fontSize: "13px" }}>
              Growdiesel is India's No.1 cleanfuel exploration and production
              organization dedicated to the cause of environment, energy and
              empowerment of masses.Growdiesel's core business is to produce and
              sell renewable cleanfuels. Our specialization lies in developing
              high-risk ideas into high-value businessess.git
            </p>
          </div>
          <div className="col-lg-4 col-sm-12">
            <h4 className="text-white mb-4 font-weight-bold text-underline">
              Quick Links
            </h4>
            <div className="row">
              <div className="col-lg-6">
                <ul>
                  <li className="happen text-left">
                    <NavLink to="/" className="points">
                      <SendIcon className="mx-2" fontSize="small"></SendIcon>{" "}
                      Home
                    </NavLink>
                  </li>
                  <li className="happen text-left">
                    <NavLink to="/story" className="points">
                      <SendIcon className="mx-2" fontSize="small"></SendIcon>{" "}
                      Our Story
                    </NavLink>
                  </li>
                  <li className="happen text-left">
                    <NavLink to="/team" className="points">
                      <SendIcon className="mx-2" fontSize="small"></SendIcon>{" "}
                      Our Team
                    </NavLink>
                  </li>
                  <li className="happen text-left">
                    <NavLink to="/journey" className="points">
                      <SendIcon className="mx-2" fontSize="small"></SendIcon>{" "}
                      Our journey
                    </NavLink>
                  </li>
                  <li className="happen text-left">
                    <Link to="/clients" className="points">
                      <SendIcon className="mx-2" fontSize="small"></SendIcon>{" "}
                      Our Clients
                    </Link>
                  </li>
                  <li className="happen text-left">
                    <Link to="/organic" className="points">
                      <SendIcon className="mx-2" fontSize="small"></SendIcon>{" "}
                      Organic
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul>
                  <li className="happen text-left">
                    <Link to="/plastic" className="points">
                      <SendIcon className="mx-2" fontSize="small"></SendIcon>
                      Plastic Waste
                    </Link>
                  </li>
                  <li className="happen text-left">
                    <Link to="/gets" className="points">
                      <SendIcon className="mx-2" fontSize="small"></SendIcon>{" "}
                      GETS
                    </Link>
                  </li>
                  <li className="happen text-left">
                    <Link to="/story" className="points">
                      <SendIcon className="mx-2" fontSize="small"></SendIcon>{" "}
                      Our Story
                    </Link>
                  </li>
                  <li className="happen text-left">
                    <Link to="/cleanair" className="points" replace>
                      <SendIcon className="mx-2" fontSize="small"></SendIcon>{" "}
                      KALPVRIKSHA
                    </Link>
                  </li>
                  <li className="happen text-left">
                    <Link to="/clinic" className="points">
                      <SendIcon className="mx-2" fontSize="small"></SendIcon>{" "}
                      Mobile Clinic
                    </Link>
                  </li>
                  <li className="happen text-left">
                    <Link to="/contact" className="points">
                      <SendIcon className="mx-2" fontSize="small"></SendIcon>{" "}
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12">
            <h4 className="text-white mb-4 font-weight-bold">Subscribe</h4>
            <p className="text-white" style={{ fontSize: "13px" }}>
              Are you looking for professional work for your new company or you
              want to boost your idea please contact us.?
            </p>
            <input
              type="text"
              placeholder="YOUR EMAIL"
              className="emaiol w-100"
            ></input>
            <br></br>
            <div className="my-3">
              <button className="btn-success">Subscribe</button>
            </div>
          </div>
        </div>
      </footer>
      
{/* <div style={{margin:"0px"}}>
   <hr className="my-0"></hr>
      <div className="row comp"  style={{marginLeft:"0px"}}>
        <span className="mx-auto text-white">
          Copyright © 2001 - 2020 GrowDiesel All Rights Reserved
        </span>
        <span className="mx-auto text-white">
           Developed By: LAKSHAY GROVER
        </span>
  </div>
      */}
      </div>
     
    //  </div>
  );
}

export default Footer;
