import React, { useEffect } from "react";
import ".././styles/Journey.css";
import $ from "jquery";

function Journey() {
  useEffect(() => {
    window.scrollTo(0, 0);
    myFunction();
    window.addEventListener('scroll', journeyScroll)
  }, [])
  function myFunction() {
    $(".content").each(function (i) {
      console.log("hello", i);
      var bottom_of_object = $(this).offset().top + $(this).outerHeight();
      var bottom_of_window = $(window).height();

      if (bottom_of_object > bottom_of_window) {
        $(this).addClass("hidden");

      }
    });
  }

  function journeyScroll() {
    $(".hidden").each(function (i) {
      // let this=i;
      var bottom_of_object = $(this).offset().top + $(this).outerHeight();
      var bottom_of_window = $(window).scrollTop() + $(window).height();

      /* If the object is completely visible in the window, fadeIn it */
      if (bottom_of_window > bottom_of_object) {
        $(this).animate({ opacity: "1" }, 800);
      }
    });
  }


  return (
    <div>
      <div className="header">
        <div className="text">
          <h1 className="major">OUR ‘green’ JOURNEY</h1>
        </div>
      </div>
      <section class="timeline">
        {/* <h1>Responsive Timeline</h1> */}

        <ul>
          <li>
            <div class="content" >
              <h2>
                <time>2001-2005</time>
              </h2>
              <p className="font-weight-bold">Research on Biofuel</p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>July 2005</time>
              </h2>
              <p className="font-weight-bold">Launch of Growdiesel</p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>July-Dec.2005</time>
              </h2>
              <p className="font-weight-bold">
                High yielding Jatropha seeds & sub. for IC Code
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2006</time>
              </h2>
              <p>
                Jatropha plantation in 100 acre wasteland near Mumbai & more
                than 10000 acres in 7 states of India
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2006</time>
              </h2>
              <p>Consultant for Indian Railway's USD 2 billion project</p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2006</time>
              </h2>
              <p>Estab. Five Biodiesel production units</p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2008</time>
              </h2>
              <p className="font-weight-bold">
                Dev. of low cost enclosed photobioreactor systems for Algae
                Biofuel
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2008</time>
              </h2>
              <p className="font-weight-bold">
                Estd. commercial biodiesel plant for a large listed company
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2009</time>
              </h2>
              <p className="font-weight-bold">
                Joined global technology consortium for treatment of waste water
                using Algae & converting to biofuels
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2009</time>
              </h2>
              <p className="font-weight-bold">
                a process to convert organic waste to cleanfuels
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2009</time>
              </h2>
              <p className="font-weight-bold">
                Estb.world's largest enclosed photobioreactor system capacity of
                one million ltr. media for production of algae for biofuels
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2009</time>
              </h2>
              <p className="font-weight-bold">
                Supplied biodiesel to HCC for running the generators during
                construction of sealink.
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2008</time>
              </h2>
              <p className="font-weight-bold">
                Dev. of low cost enclosed photobioreactor systems for Algae
                Biofuel
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2010</time>
              </h2>
              <p className="font-weight-bold">
                Dev. of catalysts and compact, advanced reactors to convert
                wet-biomass/algae/waste to cleanfuels
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2010</time>
              </h2>
              <p className="font-weight-bold">
                Estb. own biofuel equipment mfr. unit in Uttarakhand
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2010</time>
              </h2>
              <p className="font-weight-bold">
                Estb. Delhi's first waste to biofuel plant with Delhi Govt. &
                Asia's largest research institute
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2011</time>
              </h2>
              <p className="font-weight-bold">
                Dev. of process & reactor to convert MSW/Slaughter house/mixed
                waste to cleanfuels
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2011</time>
              </h2>
              <p className="font-weight-bold">
                Supplied proprietary waste to biofuel machines to asia largest
                research institute
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2011</time>
              </h2>
              <p className="font-weight-bold">
                Tech. licensing agreement with BARC
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2011</time>
              </h2>
              <p className="font-weight-bold">
                Supplied proprietary waste to biofuel machines to asia largest
                research institute
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2011</time>
              </h2>
              <p className="font-weight-bold">
                Estb. India's first project to convert MSW/Slaughter waste/mixed
                waste to cleanfuels with Municipal Corporation in Maharashtra
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2013</time>
              </h2>
              <p className="font-weight-bold">
                Successful O&M of Delhi & Maharahstra waste to biofuel plant and
                continue running
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2014</time>
              </h2>
              <p className="font-weight-bold">
                Technology up-gradation and catalyst development to treat
                slaughter-house waste to bio-cng
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2015</time>
              </h2>
              <p className="font-weight-bold">
                Technology development to treat solid and sewage waste
                (integrated)
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2015</time>
              </h2>
              <p className="font-weight-bold">
                Revamped STP plant of Gymkhana Club, New Delhi
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2016</time>
              </h2>
              <p className="font-weight-bold">
                Established Integrated Solid waste + Sewage Waste + Slaughter
                house waste to fuel project for Municipal corporation of Karnal
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2017</time>
              </h2>
              <p className="font-weight-bold">
                Up-graded technology to convert paddy straw to bio-CNG project
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2017</time>
              </h2>
              <p className="font-weight-bold">
                Submitted an action plan and DPR to 5th Delhi Finance Commission
                to tackle Delhi’s 12000 MTPD of daily waste
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2018</time>
              </h2>
              <p className="font-weight-bold">
                Established Kalpvriksha, a nano-engineered forest equivalent to
                50000 trees to fight Delhi’s air pollution in the premises of
                All India Institute of Medical Sciences, Delhi
              </p>
            </div>
          </li>
          <li>
            <div class="content">
              <h2>
                <time>2018-22</time>
              </h2>
              <p className="font-weight-bold">
                Aggressive expansion through channel partners
              </p>
            </div>
          </li>
        </ul>
      </section>
      <div
        className="row container-fluid mt-5"
        style={{ backgroundColor: "#3ded97", padding: "20px", marginLeft: "0px" }}
      >
        <div className="col-lg-9">
          <h2 className="font-weight-bold minor">Start a business or job in green energy sector</h2>
          <h2 style={{ color: "darkblue", fontWeight: "bold" }} className="minor">
            GET Funding, Technology and Market Access.
          </h2>
        </div>
        <div className="col-lg-3">
          <a href="https://education.growdiesel.com/" target="_blank" rel="noreferrer"> <button className="lak font-weight-bold">Know More</button></a>
        </div>
      </div>
    </div>
  );
}

export default Journey;
