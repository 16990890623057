import React,{useEffect} from "react";

function MobileClinic() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <div className="header">
        <div className="text">
          <h1 className="major">MEDICAL MOBILE CLINIC</h1>
        </div>
      </div>
      <div className="container">
        <div className="col-lg-9">
          <h3
            className="font-weight-bold text-left my-4"
            style={{ textDecoration: "underline" }}
          >
            Corona Mobile Clinic
          </h3>
          <iframe
            width="100%"
            height="415"
            title="25 KEY FEATURES"
            className="vansh"
            src="https://www.youtube.com/embed/ZtssHoxSsKg"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <div className="text-left">
            <h2 className="font-weight-bold text-danger text-left mt-3 ">
              25 KEY FEATURES:
            </h2>
            <ol className="my-3 text-left mt-3 kashmir">
              <li style={{ fontWeight: 600 }}>
                Artificial Intelligence enabled thermal imaging camera
              </li>
              <li style={{ fontWeight: 600 }}>
                Air tight separate cabins for doctors/technicians with sterile
                environment
              </li>
              <li style={{ fontWeight: 600 }}>
                Sterile and sanitizing equipment for interior cleansing
              </li>
              <li style={{ fontWeight: 600 }}>
                High speed internet enabled with LAN and Wi-Fi facility
              </li>
              <li style={{ fontWeight: 600 }}>
                {" "}
                24x7 camera surveillance facility for central monitoring
              </li>
              <li style={{ fontWeight: 600 }}>
                Ultraviolet Light protection and sterilization system to kill
                bacteria
              </li>
              <li style={{ fontWeight: 600 }}>
                Profile guiding lights for patients and doctors along with floor
              </li>
              <li style={{ fontWeight: 600 }}>
                Contactless Wi-Fi enabled control panel for doctor/technician to
                operate electrical system.
              </li>
              <li style={{ fontWeight: 600 }}>
                Ultra-pure air releasing system powered by KalpaVrikshaTM
                technology
              </li>
              <li style={{ fontWeight: 600 }}>
                Stretcher available for transporting patient
              </li>
              <li style={{ fontWeight: 600 }}>
                Diffuser based sanitizing system for doctor’s and patient’s zone
              </li>
              <li style={{ fontWeight: 600 }}>
                {" "}
                Partition attached with gloves to conduct seamless sample
                collection
              </li>
              <li style={{ fontWeight: 600 }}>
                Static sample pass box with interlocking system powered by UV
                cleaning system to store samples
              </li>
              <li style={{ fontWeight: 600 }}>
                Touch less two-way communication system for doctor patient and
                doctor Driver
              </li>
              <li style={{ fontWeight: 600 }}>
                Thermal monitoring screen powered by android operating system.
              </li>
              <li style={{ fontWeight: 600 }}>
                Touch-less sensor based sanitizing dispenser for doctor as well
                as patient at entry.
              </li>
              <li style={{ fontWeight: 600 }}>
                Climate control system for doctors and patients in place
              </li>
              <li style={{ fontWeight: 600 }}>
                {" "}
                Light Emitting Diode based message display system with
                modification facility through app based system.
              </li>
              <li style={{ fontWeight: 600 }}>
                Separate entry gates for doctors and patients
              </li>
              <li style={{ fontWeight: 600 }}>
                Cloud based data storage facility
              </li>
              <li style={{ fontWeight: 600 }}>
                Separate Ambulance zone to evacuate critical patients
              </li>
              <li style={{ fontWeight: 600 }}>
                Mobile sanitation system based on dot spray technology for
                outdoor cleaning of the bus.
              </li>
              <li style={{ fontWeight: 600 }}>
                On board power supply with a digiset
              </li>
              <li style={{ fontWeight: 600 }}>
                separate air conditioning for patient zone and doctor zone
              </li>
              <li style={{ fontWeight: 600 }}>
                Low floor slider added for easy movement of patients.
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div
        className=" container-fluid mt-5"
        style={{ backgroundColor: "#3ded97", padding: "20px" ,marginLeft:"0px"}}
      >
        <div className="row">

        <div className="col-lg-8">
          <h2 className="font-weight-bold minor">Start your own bio-refinery</h2>
          <h2 style={{ color: "darkblue",fontWeight:"bold" }} className="minor">
            GET Funding, Technology and Market Access.
          </h2>
        </div>
        <div className="col-lg-4 ">
          <button className="lak mx-3 font-weight-bold">
            <span>WhatApp Us</span>
          </button>
          <button className="lak font-weight-bold">
            <span>Know More</span>
          </button>
        </div>
        </div>
      </div>
    </div>
  );
}

export default MobileClinic;
