import React,{useEffect} from "react";

function Trainings() {
 
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])
  return (
    <div>
      <div className="header">
        <div className="text">
          <h1 className="major">TRAININGS</h1>
        </div>
      </div>
      <h1 className="p-4">
        Site Training + LIVE class-room session certification Rs. 25000 + taxes
        (includes local travel, stay, food)
      </h1>
    </div>
  );
}

export default Trainings;
