import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/Home.css";
import Image from "./Imagessection";
import Private from "./Private";
import Video from "./Video";
import Testomonials from "./Testomonials";
import Projects from "./Projects";
import Initative from "./Initative";
import Top from "./Top";
import $ from "jquery";
// import Footer from "./Footer";
import { NavLink } from "react-router-dom";
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';


function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('scroll', counterScroll)
  }, [])
  var a = 0;
  var b = 0;
  function counterScroll() {
    if (window.pageYOffset > 1200) {


      //var oTop = $(".counter").offset().top - window.innerHeight;
      if (a === 0 && $(window).scrollTop()) {
        $(".counter-value").each(function () {
          var $this = $(this),
            countTo = $this.attr("data-count");
          $({
            countNum: $this.text(),
          }).animate(
            {
              countNum: countTo,
            },
            {
              duration: 4000,
              easing: "swing",
              step: function () {
                $this.text(commaSeparateNumber(Math.floor(this.countNum)));
              },
              complete: function () {
                $this.text(commaSeparateNumber(this.countNum));
                //alert('finished');
              },
            }
          );
        });
        a = 1;
      }
    }
    if (window.pageYOffset > 1500) {


      //  var oTop = $(".counter").offset().top - window.innerHeight;
      if (b === 0 && $(window).scrollTop()) {
        $(".counter-value-2").each(function () {
          var $this = $(this),
            countTo = $this.attr("data-count");
          $({
            countNum: $this.text(),
          }).animate(
            {
              countNum: countTo,
            },
            {
              duration: 4000,
              easing: "swing",
              step: function () {
                $this.text(commaSeparateNumber(Math.floor(this.countNum)));
              },
              complete: function () {
                $this.text(commaSeparateNumber(this.countNum));
                //alert('finished');
              },
            }
          );
        });
        b = 1;
      }
    }
  }

  function commaSeparateNumber(val) {
    while (/(\d+)(\d{3})/.test(val.toString())) {
      val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
    }
    return val;
  }
  // document.getElementById(".card").addEventListener("mouseover").
  // $(".card").mouseover(function () {
  //   $(".carousel-control-next-icon").css({
  //     background: "red",
  //     padding: "30px",
  //   });
  // });
  return (
    <>
      <Top ></Top>
      <div
        className="mx-auto my-5 container-fluid addpad"
        id="addpad"
      >
        <section className="text-center dark-grey-text">
          <div className="row">
            {/* <!-- Grid column --> */}
            <div className="col-lg-4 col-md-12 mb-4">
              {/* <!-- Card --> */}
              <div className="card main shadow">
                <img
                  src="images/s5.jpg"
                  className="party"
                  width="100%"
                  height="260px"
                  alt="hand"
                />
                {/* <!-- Content --> */}
                <div className="card-body">
                  <div className="d-flex justify-content-center"></div>

                  {/* <!-- Price --> */}
                  <h4 className="font-weight-bold my-2 maintext3">VISION</h4>
                  <p className="contentofcard">
                    <span style={{ fontWeight: "bold" }}> Growdiesel</span>,
                    Inline with nature's laws to community we are putting in
                    place strong foundations to make Growdiesel, a safer, more
                    trusted and more valuable organization. As part of this
                    process we have following values that express our shared
                    understanding of what we believe and what we aspire to be as
                    an organization.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div className="card main shadow">
                <img
                  src="images/Mission.jpg"
                  className=" party"
                  width="100%"
                  height="260px"
                  alt="hand"
                />
                {/* <!-- Content --> */}
                <div className="card-body white-text">
                  <h4 className="font-weight-bold my-2 maintext3">MISSION</h4>
                  <p className="contentofcard">
                    To let organisations, states, campuses achieve fuel
                    independence. Growdiesel is committed to invest earth’s
                    natural capital for social development, carbon sequestration
                    and energy independence to make this world a better place to
                    live.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              {/* <!-- Card --> */}
              <div className="card main shadow">
                <img
                  src="images/sValues.jpg"
                  className=" party"
                  width="100%"
                  height="260px"
                  alt="hand"
                />
                {/* <!-- Content --> */}
                <div className="card-body">
                  {/* <!-- Offer --> */}

                  {/* <!--Price --> */}
                  <h4 className="font-weight-bold my-2 maintext3">VALUES</h4>
                  <p className="grey-text contentofcard" style={{ margin: "0px" }}>
                    <span style={{ fontWeight: "bold" }}> Growdiesel</span>,
                    Inline with nature's laws to community we are putting in
                    place strong foundations to make Growdiesel, a safer, more
                    trusted and more valuable organization. As part of this
                    process we have following values that express our shared
                    understanding of what we believe and what we aspire to be as
                    an organization.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* impact created============================ */}
      {/* <Parallax></Parallax> ++++++++++++++++++++++++++++++++++++++++++++++++++*/}
      <div className="start"></div>
      <div className="parallax ">
        <div className="body text-center">
          <h1 className="text-white paralaxconmtent ">
            <span id="span" style={{ color: "#3ded97" }}>
              “Get paid
            </span>{" "}
            to save environment.”
          </h1>
          <div>
            {/* <NavLink to="/story"> */}
              <button className="btn-grad2"><a href="https://growdiesel.in/webinar/" target="_blank" rel="noreferrer" style={{color:"black"}}>Know More</a></button>
            {/* </NavLink> */}
          </div>
        </div>
      </div>
      <div className="start"></div>
      {/* paralax ends herd+++++++++++++++++++++++++++++++++++++++++++++++= */}
      {/* <div className="endinglines"></div> */}
      <h3 className="text-center font-weight-bold mb-2 heading">
        IMPACT CREATED
      </h3>

      <div className="container-fluid my-3">
        <section className="p-5 z-depth-1">
          <div className="row d-flex justify-content-center">
            <div className="col-md-6 col-lg-3 mb-4 text-center ">
              <div className="card card2">
                <h4 className="background mb-3">
                  {/* <i className="fas fa-pencil-ruler indigo-text"></i> */}
                  <span
                    className="d-inline-block count font-weight-bold counter-value"
                    data-count="250000"
                  >
                    0
                  </span>
                  {""}
                </h4>
                <p className="smltext">
                  METRIC TONS OF MUNICIPAL WASTE ELIMINATED
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4 text-center ">
              <div className="card card2 ">
                <h4 className="background mb-3">
                  <span
                    className="d-inline-block count font-weight-bold counter-value"
                    data-count="10"
                  >
                    0
                  </span>
                  {" "}
                  <span className="d-inline-block count font-weight-bold">

                    {" "} MILLION +
                  </span>
                </h4>
                <p className="smltext">TONS OF CARBON EMISSIONS REMOVED</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 mb-4 text-center">
              <div className="card card2">
                <h4 className="background mb-3">
                  <span
                    className="d-inline-block count font-weight-bold counter"
                    // data-from="0"
                    // data-to="250"
                    // data-time="2000"

                    id="counter"
                    data-counter="10"
                    data-timer="500"
                  >
                    $5 {" "}MILLION+
                  </span>
                </h4>
                <p className="smltext">
                  OF PUBLIC’S MONEY <br></br>SAVED
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 mb-4 text-center ">
              <div className="card card2 ">
                <h4 className="mb-3 background">
                  <span
                    className="d-inline-block count font-weight-bold counter-value"
                    data-count="20"
                  >
                    0
                  </span>
                  {" "}
                  <span className="d-inline-block count font-weight-bold">
                    {" "}
                    MILLION +
                  </span>
                </h4>
                <p className="smltext">KILOGRAMS OF BIOFUEL GENERATED</p>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <div className="endinglines"></div> */}
      <Video></Video>
      {/* <Parallax></Parallax>+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
      <div className="start"></div>
      <div class="parallax ">
        <div className="body text-center">
          <h1 className="text-white paralaxconmtent">
            “Kick start your journey and convert<br></br>
            <span id="span" style={{ color: "#3ded97" }}>
              Waste 2 Wealth.”
            </span>
          </h1>
          <div>
            <NavLink to="/story" ><button className="btn-grad2">Know More</button></NavLink>
          </div>
        </div>
      </div>
      <div className="start"></div>
      {/* value addition done ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}
      {/* <div className="endinglines"></div> */}
      <h3 className="text-center font-weight-bold mb-2 heading">
        VALUE ADDITION DONE
      </h3>

      <div className="container-fluid my-3">
        <section className="p-5 z-depth-1">
          <div className="row d-flex justify-content-center">
            <div className="col-md-6 col-lg-3 mb-4 text-center ">
              <div className="card card2">
                <h4 className="background mb-3">
                  {/* <i className="fas fa-pencil-ruler indigo-text"></i> */}
                  <span
                    className="d-inline-block count font-weight-bold counter-value-2"
                    data-count="5000"
                  >
                    0
                  </span>
                  <span className="d-inline-block count font-weight-bold">
                    +
                  </span>
                </h4>
                <p className="smltext">PEOPLE TRAINED</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mb-4 text-center ">
              <div className="card card2 ">
                <h4 className="background mb-3">
                  <span
                    className="d-inline-block count font-weight-bold counter-value-2"
                    data-count="75"
                  >
                    0
                  </span>
                  <span className="d-inline-block count font-weight-bold">
                    +
                  </span>
                </h4>
                <p className="smltext">ORGANISATIONS SERVED</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 mb-4 text-center">
              <div className="card card2">
                <h4 className="background mb-3">
                  <span
                    className="d-inline-block count font-weight-bold counter-value-2"
                    data-count="50"
                  >
                    0
                  </span>
                  <span className="d-inline-block count font-weight-bold">
                    +
                  </span>
                </h4>
                <p className="smltext">PATENTS FILED</p>
              </div>
            </div>

            <div className="col-md-6 col-lg-3 mb-4 text-center ">
              <div className="card card2 ">
                <h4 className="mb-3 background">
                  <span
                    className="d-inline-block count font-weight-bold counter-value-2"
                    data-count="400"
                  >
                    0
                  </span>
                  <span className="d-inline-block count font-weight-bold">
                    +
                  </span>
                </h4>
                <p className="smltext">SPEAKER SESSIONS</p>
              </div>
            </div>
          </div>
        </section>
        {/* <!--Section: Content--> */}
      </div>

      {/* gallery+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
      {/* govt served */}
      <Image></Image>
      <Private></Private>
      {/* Founders note */}
      <h1 className="text-center heading">FOUNDER'S NOTE</h1>
      <div className="container-fluid my-4">
        <div className="row">
          <div className="col-lg-5 col-sm-12 ">
            <img src="About/0. Atul Saxena (Founder).jpg" className="img-fluid"></img>
          </div>
          <div className="col-lg-7 col-sm-12">
            <p style={{ fontSize: "18px", fontWeight: "600" }} className="text-left founders">
              “After rejecting an investment offer of{" "}
              <span className="text-danger font-weight-bold">
                {" "}
                Rs. 140 Crore
              </span>{" "}
              from one of the largest Indian conglomerates, I knew that the
              journey will be a little longer but exciting. Before I started
              Growdiesel, I was working with United Nations that gave me a
              vision to do something for the environment and society. However, I
              knew that will practically not work if economic aspect wasn’t
              taken into consideration. Hence, our team developed a robust
              business model that was technically and economically feasible.
            </p>
            <p style={{ fontSize: "18px", fontWeight: "600" }} className="text-left my-4">
              We all talk about creating{" "}
              <span className="text-success font-weight-bold">
                “Green jobs”{" "}
              </span>
              but before we create green jobs, we need to identify and create
              <span className="text-success font-weight-bold">
                {" "}
                “Green Entrepreneurs”
              </span>
              . That’s my vision. Growdiesel is offering an ecosystem to those
              people who will create “Green jobs”. An ecosystem that has{" "}
              <span className="text-success font-weight-bold">
                {" "}
                Funding, Technology and Market access.
              </span>
            </p>
            <p style={{ fontSize: "18px", fontWeight: "600" }} className="text-left">
              Be a part of the revolution. This is the best time to be a part of
              the Green Energy Sector.”
            </p>
          </div>
        </div>
      </div>
      {/* Testominal */}
      <Testomonials />
      {/* major projects section */}

      <Projects></Projects>
      {/* <Parallax></Parallax>+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
      <div className="start"></div>
      <div className="parallax ">
        <div className="body text-center">
          <h1 className="text-white paralaxconmtent">
            “Do good for the environment and{" "}
            <span id="span" style={{ color: "#3ded97" }}>
              earn money
            </span>{" "}
            <br></br> for doing it.”
            <span id="span" style={{ color: "#3ded97" }}></span>
          </h1>
          <div>
            <NavLink to="/story">
              <button className="btn-grad2">Know More</button>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="start"></div>
      {/* value addition done ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}
      <Initative className="inis" />
      {/* <Parallax></Parallax>+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
      <div className="start"></div>
      <div class="parallax ">
        <div className="body text-center">
          <h1 className="text-white paralaxconmtent">
            “Do good for the society and <br></br>
            <span id="span" style={{ color: "#3ded97" }}>
              earn great respect.”
            </span>{" "}
            <span id="span" style={{ color: "#3ded97" }}></span>
          </h1>
          <div>
            <NavLink to="/story">

              <button className="btn-grad2">Know More</button>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="start"></div>
      {/* value addition done ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}
      {/* <Footer></Footer> */}

    </>
  );
}

export default Home;
