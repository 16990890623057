import React,{useEffect} from "react";
import Testomonials from "../Testomonials";

function Test() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <div className="header">
        <div className="text">
          <h1>TESTOMONIALS</h1>
        </div>
      </div>
      <div className="laks">
        <Testomonials></Testomonials>
      </div>
    </div>
  );
}

export default Test;
