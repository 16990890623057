import React from "react";
import ".././styles/Videokk.css";


function Video() {
  return (
    <div>
      <div className="header">
        <div className="text">
          <h1>Video Section</h1>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row my-4">
          <div className="col-lg-4 col-sm-12 md-4 ">
            <div className="card jacob">

              <iframe width="100%" height="300" src="https://www.youtube.com/embed/2Bdlc5g6W9U" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 md-4">
            <div className="card jacob">

              <iframe width="100%" height="300" src="https://www.youtube.com/embed/_K4fclLvxeQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 md-4">
            <div className="card jacob">

              <iframe width="100%" height="300" src="https://www.youtube.com/embed/oQMTgHZnaCk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-lg-4 col-sm-12 md-4 ">
            <div className="card jacob">

              <iframe width="100%" height="300" src="https://www.youtube.com/embed/rgeKqwsVAwk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 md-4">
            <div className="card jacob">

              <iframe width="100%" height="300" src="https://www.youtube.com/embed/JxIic1Z1Qac" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 md-4">
            <div className="card jacob">

              <iframe width="100%" height="300" src="https://www.youtube.com/embed/MgOHJtFITuU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-lg-4 col-sm-12 md-4 ">
            <div className="card jacob">

              <iframe width="100%" height="300" src="https://www.youtube.com/embed/hHuiR5hBImE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 md-4">
            <div className="card jacob">

              <iframe width="100%" height="300" src="https://www.youtube.com/embed/jTkFVUlOwCI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 md-4">
            <div className="card jacob">

              <iframe width="100%" height="300" src="https://www.youtube.com/embed/cXXe4HwdIzo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-lg-4 col-sm-12 md-4 ">
            <div className="card jacob">

              <iframe width="100%" height="300" src="https://www.youtube.com/embed/ZtssHoxSsKg" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 md-4">
            <div className="card jacob">

              <iframe width="100%" height="300" src="https://www.youtube.com/embed/wAqTxdDaVnE" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 md-4">
            <div className="card jacob">

              <iframe width="100%" height="300" src="https://www.youtube.com/embed/6B-m8GlsBl0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-lg-4 col-sm-12 md-4 ">
            <div className="card jacob">

              <iframe width="100%" height="300" src="https://www.youtube.com/embed/Dvokk-cH-Xc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 md-4">
            <div className="card jacob">

              <iframe width="100%" height="300" src="https://www.youtube.com/embed/p371q4-TMak" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <div className="col-lg-4 col-sm-12 md-4">
            <div className="card jacob">

              <iframe width="100%" height="300" src="https://www.youtube.com/embed/ZfYQWBBTtQQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Video;
