import React from "react";
import "./styles/Image.css";

function Private() {
  return (
    <div>
      <h2 className="text-center heading">PRIVATE ORGANISATIONS SERVED</h2>
      <div className="container-fluid">
        <marquee behavior="alternate" direction="left" className="jamun">
          {/* <div className="scroll"> */}
          <img
            src="pvt/1. Sonalika tractors.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="1"
          />

          <img
            src="pvt/3.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="3"
          />
          <img
            src="pvt/4.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="4"
          />

          <img
            src="pvt/6.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="6"
          />
          <img
            src="pvt/7.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="7"
          />
          <img
            src="pvt/8.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="8"
          />
          <img
            src="pvt/9.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="9"
          />
          <img
            src="pvt/10.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="10"
          />
          <img
            src="pvt/11.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="11"
          />
          <img
            src="pvt/5.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="5"
          />
          <img
            src="pvt/18.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="18"
          />
          <img
            src="pvt/12.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="12"
          />
          <img
            src="pvt/13.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="13"
          />
          <img
            src="pvt/14.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="14"
          />
          <img
            src="pvt/2.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="2"
          />
          <img
            src="pvt/15.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="15"
          />
          <img
            src="pvt/16.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="16"
          />
          <img
            src="pvt/17.png"
            width="250px"
            height="250px"
            className="img"
            alt="sonalika tractors"
            key="17"
          />
          {/* </div> */}
        </marquee>
      </div>
    </div>
  );
}
export default Private;
