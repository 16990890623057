import React, { useEffect } from "react";
import ".././styles/Contact.css";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import LocationOnIcon from "@material-ui/icons/LocationOn";
// import { LocationOn } from "@material-ui/icons";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { NavLink } from "react-router-dom";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <header className="kamkro">
        <div className="yoyo">{/* <h1>CONTACT US</h1> */}</div>
      </header>
      <div className="container my-5 p-4">
        <div className="row">
          <div className="col-lg-6 col-sm-12 ">
            <div className="card p-4 border-1 happyu">
              <h2 className="text-center mb-3 font-weight-bold">CONTACT US</h2>
              {/* <h5 className="text-left my-2">LOCATION:</h5> */}
              <p className="text-left my-3 font-italic font-weight-bold">
                <LocationOnIcon className="text-success"></LocationOnIcon>{" "}
                SF-51, 2nd Floor , Crossriver Mall , Karkarduma , Delhi
              </p>
              <p className="text-left font-italic font-weight-bold" >
                <PhoneIcon className="text-success"></PhoneIcon>{" "}
                +91-9212844166 , +91-9212601012
              </p>
              <p className="text-left mb-3 font-italic font-weight-bold" >
                <MailIcon className="text-success"></MailIcon>{" "}
                Growdiesel@gmail.com , info@Growdiesel.com
              </p>
              <a href="https://chat.whatsapp.com/Htnroc2KfzREpY24FtsddS" className="text-left" target="_blank" rel="noreferrer">
                <WhatsAppIcon className="text-success"></WhatsAppIcon> Whatsapp
                us
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <input
              type="text"
              placeholder="NAME"
              className="mx-auto w-100  mess mb-3"
            />
            <input
              type="text"
              placeholder="EMAIL"
              className="mx-auto w-100 mess"
            />
            <input
              type="type"
              placeholder="SUBJECT"
              className="mx-auto w-100 mess my-3"
            />
            <textarea
              cols="13"
              rows="5"
              placeholder="MESSAGE"
              className="w-100 p-2"
            ></textarea>
            <div className="text-left">
              <button className="btn-success text-center w-100 ">SUBMIT</button>
            </div>
          </div>
        </div>
      </div>
      {/* <Parallax></Parallax>+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
      <div className="start"></div>
      <div className="parallax ">
        <div className="body text-center" >
          <h1 className="text-white paralaxconmtent">
            “Become a part of the <br></br>
            <span id="span" style={{ color: "#3ded97" }}>
              fuel revolution.”
            </span>{" "}
            <span id="span" style={{ color: "#3ded97" }}></span>
          </h1>
          <div className="mt-2">
            <NavLink to="/story">

              <button className="btn-grad2">Know More</button>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="start"></div>
      {/* value addition done ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}
    </div>
  );
}

export default Contact;
