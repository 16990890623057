import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./styles/Projects.css";

function Projects() {
  return (
    <div>
      <h1 className="heading">MAJOR PROJECTS AND ASSOCIATION</h1>
      <div>
        <Carousel className="my-4" id="addpad2">
          <Carousel.Item>
            <div className="conatiner-fluid mx-auto">
              <div className="row container-fluid mx-auto">
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Projects/1. Delhi waste 2 biofuel.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname2">
                        DELHI’S FIRST WASTE TO BIOFUEL PROJECT
                      </h4>

                      <p className="card-title  mb-4">
                        The project was established at Delhi Chief Minister’s
                        office and was inaugurated by former Chief Minister of
                        Delhi, Smt. Sheila Dikshit ji in August 2010. The
                        project has eliminated more than 1.5 million kgs of
                        waste and delivered 7500+ cylinders equivalent of
                        cooking gas. This project has been successful in
                        eliminating huge amount of carbon emissions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Projects/2. VIP car.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname2">
                        Growdiesel’s biodiesel being used in VIP vehicles
                      </h4>

                      <p className="card-title  mb-4">
                        Former Chief Minister of Maharashtra Sh. Vilas Rao
                        Deshmukh ji demonstrating Growdiesel’s biodiesel in his
                        vehicles and few other government vehicles. The occasion
                        was attended by 1000+ people and our efforts were
                        appreciated at various government levels.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Projects/3. Biofuel Refinery.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname2 ">
                        INDIA’S FIRST WASTE TO BIOFUEL REFINERY
                      </h4>

                      <p className="card-title  mb-4">
                        This biofuel refinery is designed by team Growdiesel
                        with extreme precision that converts organic/agriculture
                        waste into Bio-CNG and Plastic waste into Green Diesel.
                        This was has been a benchmark project for our new
                        projects. It is a reactor based technology which is
                        patented by Growdiesel.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="conatiner-fluid mx-auto">
              <div className="row container-fluid mx-auto">
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Projects/4. Waste to PNG refinery.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname2">
                        WASTE TO BIO-PNG REFINERY
                      </h4>

                      <p className="card-title  mb-4">
                        This project was established by Growdiesel for Roha
                        Municipal Council where organic waste is collected,
                        processed and converted into Bio-PNG. The bio-PNG is
                        then supplied to nearby restaurants through pipeline.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Projects/5. Bandra worli sea link.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname2">
                        Mumbai’s Bandra-Worli sea link
                      </h4>

                      <p className="card-title  mb-4">
                        During the construction of Mumbai’s Bandra Worli Sea
                        Link, there was a notification that underground
                        generators can not run on fossil fuels and biofuels were
                        to be used. Growdiesel was the official supplier of
                        biofuel.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Projects/6. Indian Railways.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname2">
                        Project for Indian Railways
                      </h4>

                      <p className="card-title  mb-4">
                        Chosen as consultants to Indian Railways through a two
                        stage global bidding process. Created a PPP business
                        model to plant 40000 Ha of spare railway land with
                        Jatropha & use the resultant biodiesel for running
                        engines.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="conatiner-fluid mx-auto">
              <div className="row container-fluid mx-auto">
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Projects/7. Formula Car racing.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname2">
                        GROWDIESEL’S FORMULA 4 CAR RACING TEAM
                      </h4>

                      <p className="card-title  mb-4">
                        Growdiesel sponsored a formula 4 car racing team to
                        increase the awareness of using environment friendly
                        fuels. Our team ended up winning 8/10 races held at
                        Coimbatore, India. The initiative was widely appreciated
                        by various organizations.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Projects/8. Corona Testing Mobile Clinic.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname2">
                        Corona Testing Mobile Clinic
                      </h4>

                      <p className="card-title  mb-4">
                        Growdiesel donated corona testing mobile clinics to the
                        Delhi government which were flagged off by hon’ble chief
                        secretary of Delhi, Sh. Vijay Kumar Dev, IAS in presence
                        of several high level officials. 1 mobile clinic
                        provides more than 15000+ free testing services at
                        doorstep to residents of Delhi every month.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Projects/9. Electric Vehicles.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname2">
                        ELECTRIC VEHICLES MADE BY TEAM MEMBERS
                      </h4>

                      <p className="card-title  mb-4">
                        Growdiesel Electric transportation System (GETS) is the
                        newest edition to our business verticals and our team of
                        highly experienced engineers and scientists have been in
                        EV Industry for over 10+ years. Biofuels will play a
                        major role in the electric vehicle charging industry and
                        we have several opportunities in the sector as we plan
                        to integrate.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="conatiner-fluid mx-auto">
              <div className="row container-fluid mx-auto">
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow shadow">
                    <img
                      src="Projects/10. Paddy Stubble to Bio-CNG project.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname2">
                        PADDY STUBBLE TO BIO-CNG PROJECT
                      </h4>

                      <p className="card-title  mb-4">
                        Growdiesel commissioned an integrated project for the
                        Haryana government, India. This project has the
                        potential to convert over 50 million kgs of paddy
                        stubble into bio-CNG. This project can also be used to
                        process slaughter-house waste, municipal solid waste,
                        sewage and mandi waste. 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Projects/11. Waste water to clean water.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname2">
                        WASTE WATER TO CLEAN WATER AT DELHI GYMKHANA CLUB
                      </h4>

                      <p className="card-title  mb-4">
                        Our team got an opportunity to serve the prestigious
                        Delhi Gymkhana club. We commissioned a project for them
                        which was highly appreciated by their entire management
                        as we had delivered the project in a very short span of
                        time. The project converts waste water into clean water.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12">
                  <div className="card back shadow">
                    <img
                      src="Projects/12. International association.jpg"
                      alt="img"
                      className="img-fluid testo"
                    />
                    <div className="card-body">
                      <h4 className="font-weight-bold mainname2">
                        International associations
                      </h4>

                      <p className="card-title  mb-4">
                        Chosen as the sole Indian partner within a global
                        network of European, US & Asian institutions & companies
                        to develop a technology for treating waste water with
                        Algae & convert it to biofuels.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}
export default Projects;
