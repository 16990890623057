
import React, { useEffect } from "react";
import ".././styles/Vendor.css";

function Vendor() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div>
            <div className="header">
                <div className="text">
                    <h1 className="major text-uppercase">Opportunities AT Growdiesel</h1>
                </div>
            </div>
            <div className="mainvendor container">
                <div className="row">
                    <div className="col-lg-4 col-sm-12 " style={{ padding: "17px" }}>
                        <div className="card golu shadow" style={{ padding: "10px 10px" }}>
                            <h5 className="topic">BECOME OUR CONSULTANT</h5>
                            <hr></hr>
                            <p className="vendorp">Growdiesel is aggressively expanding its operations and looking for passionate people willing to work as consultants.</p>
                            <hr />
                            <button className="btn btn-success">Email Us : hr@growdiesel.com</button>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 " style={{ padding: "17px" }}>
                        <div className="card golu shadow" style={{ padding: "10px 10px" }}>
                            <h5 className="topic">BECOME OUR CHANNEL PARTNER</h5>
                            <hr></hr>
                            <p className="vendorp">Growdiesel is aggressively expanding its operations and looking for passionate people willing to work as Channel Partner.</p>
                            <hr />
                            <button className="btn btn-success">Email Us : hr@growdiesel.com</button>
                        </div>
                    </div> <div className="col-lg-4 col-sm-12 " style={{ padding: "17px" }}>
                        <div className="card golu shadow" style={{ padding: "10px 10px" }}>
                            <h5 className="topic">APPLY FOR INTERNSHIP / JOB</h5>
                            <hr></hr>
                            <p className="vendorp">We are looking for extremely passionate and creative team. Do you think you are innovative enough ? yes, email us your CV.</p>
                            <hr />
                            <button className="btn btn-success">Email Us : hr@growdiesel.com</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mainvendor container">
                <div className="row">
                    <div className="col-lg-4 col-sm-12 " style={{ padding: "17px" }}>
                        <div className="card golu shadow" style={{ padding: "10px 10px" }}>
                            <h5 className="topic">BECOME A GROWDIESEL OPPORTUNITY ACCELERATOR (GOA)</h5>
                            <hr></hr>
                            <p className="vendorp">Growdieselis inviting applications from passionate individuals to join as GOA (Growdiesel opportunity accelerator) and get a chance for acceleration and own a biofuel Unicorn GOAL. Each new cleanfuel enterprise shall create 1000 green jobs and green economic opportunities for over 10000 people.</p>
                            <hr />
                            <a href="pdf/Entrepreneur-GOA-intro.pdf" target="_blank" className="btn btn-success">Download Now</a>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 " style={{ padding: "17px" }}>
                        <div className="card golu shadow" style={{ padding: "10px 10px" }}>
                            <h5 className="topic">SELL/LEASE YOUR LAND</h5>
                            <hr></hr>
                            <p className="vendorp" style={{marginBottom: "-8px",
    marginTop: "0px"}}>Do you have land bank and want to sell / lease or do a partnership with your land? We are looking for people who want to enter such partnerships. Kindly send an email with the following details:
                                
                                <br></br>
                                <br></br>
                                <ul style={{padding:"10px",alignContent:'left'}}>
                                    <li className="text-left">Total available Land</li>
                                    <li className="text-left">Exact Location of land</li>
                                    <li className="text-left">Expected selling / leasing price per acre</li>
                                    <li className="text-left">Are you also Interested in a partnership?</li>
                                </ul>
                            </p>
                            <hr />
                            <button className="btn btn-success">Email Us : hr@growdiesel.com</button>
                        </div>
                    </div> <div className="col-lg-4 col-sm-12 " style={{ padding: "17px" }}>
                        <div className="card golu shadow" style={{ padding: "10px 10px" }}>
                            <h5 className="topic">BECOME OUR VENDOR/SUPPLIER</h5>
                            <hr></hr>
                            <p className="vendorp">For establishing these biomass to biofuel projects all around India & across the world, Growdiesel is interested in empanelment of vendors for fabrication & purchase of equipments and services. Growdiesel plans to enroll Original Equipment Manufacturers (OEMs), their channel partners, service providers & authorized distributors.</p><br></br>
                            <hr />
                            <a href="pdf/Vendor-Empanelment-Form-2018.pdf" target="_blank" className="btn btn-success">Download Now</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );
}

export default Vendor;
