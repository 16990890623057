import React, {
  useEffect,
  useRef,
} from "react";
import { Link} from "react-router-dom";
import { NavLink } from "react-router-dom";
import "./styles/slide-menu.css";
import "../node_modules/@grubersjoe/slide-menu/dist/slide-menu";
import "../node_modules/@grubersjoe/slide-menu/dist/slide-menu.css";
import "./styles/Mainmenu.css";
import "./styles/topButton.css";

export default function Mainmenu() {
  var menu;
  var topBtn = useRef("")
  var header = useRef("");
  var sidebar = useRef("");
  var sidebar2 = useRef("");
  var more_sub_links = useRef("");
  var about_sub_links = useRef("");
  var more_media_sub_links = useRef("");
  var more_event_sub_links = useRef("");
  var mobileSideNav = useRef("");
  var about_innovation_sub_links = useRef("");
  var about_services_sub_links = useRef("");
  var currentLink;
  var currentSubLink;

  // var [currentLink, setCurrentLink] = useState();
  // console.log(sidebar, sidebar2, more_sub_links, about_sub_links);




  useEffect(() => {

    const menuElement = mobileSideNav.current;
    // console.log(menuElement);

    menu = new window.SlideMenu(menuElement, {
      position: "left",
      submenuLinkAfter:
        '<span style="margin-left: 1em; font-size: 85%; font-weight:900;" >&gt;</span>',
      backLinkBefore:
        '<span style="margin-right: 1em; font-size: 85%; font-weight:900;">&lt;</span>'
    });

    // console.log(menu)

    window.addEventListener('scroll', myScroll)

  }, []);



  function showMenu() {
    // console.log("menu loaded");
    window.document.body.style.overflowY = "hidden";
    // mobileSideNav.current.classList.remove("close-menu");
    mobileSideNav.current.classList.add("open-menu");
    menu.open();

    // Attach the event listener to the *DOM element*, not the SlideMenu instance
  }
  function closeMenu() {
    mobileSideNav.current.classList.remove("open-menu");
    mobileSideNav.current.classList.add("close-menu");
    window.document.body.style.overflowY = "scroll";
  }

  function headerMouseOver() {
    header.current.classList.add("scrollHeader");
  }
  function headerMouseLeave() {
    // if (sidebar.current.hidden == true )
    //   header.current.classList.remove("scrollHeader");
    if (window.pageYOffset < 100) {
      if (sidebar.current.hidden === true)
        header.current.classList.remove("scrollHeader");
    }
  }
  document.onmousemove = function (event) {

    if (!event.target.classList.contains('nav-link')) {
      // console.log(event, event.target.classList.contains('link1'))
      if (!event.target.classList.contains('headerhm') && !event.target.classList.contains('link1') && !event.target.classList.contains('sidenav')) {
        if (!sidebar.current.hidden) {
          if (sidebar2.current.hidden)
            handleSidebarMouseLeave();
        }

      }

    }
  }

  function handleMouseOver(event) {

    if (currentLink !== undefined && currentLink.classList.contains("active8")) {
      currentLink.classList.remove("active8");

    }

    // console.log("mouse", event.target);
    currentLink = event.target;
    currentLink.classList.add("active8");

    // console.log("link event", event.target.innerHTML.toLowerCase().trim());
    if (event.target.innerHTML.includes("About")) {
      // console.log("inside about ua");

      more_sub_links.current.hidden = true;
      about_sub_links.current.hidden = false;
    } else if (event.target.innerHTML.includes("More")) {
      more_sub_links.current.hidden = false;
      about_sub_links.current.hidden = true;
    } else {
      sidebar.current.hidden = true;
      sidebar2.current.hidden = true;
      return;
    }
    sidebar.current.hidden = false;
    sidebar.current.focus = true;

    window.document.body.style.overflowY = "hidden";

    if (sidebar2.current.hidden === false) {
      sidebar2.current.hidden = true;
    }
    //sidebar.current.style.left = 0 + "px";
  }
  function handleMouseLeave() {

    window.document.body.style.overflowY = "scroll";

    if (sidebar.current.hidden) {
      if (currentLink !== undefined) {
        currentLink.classList.remove("active8");
      }
    }
  }
  function handleSidebarMouseLeave() {

    if (sidebar2.current.hidden === true) {
      sidebar.current.hidden = true;
      sidebar.current.focus = false;
      if (window.pageYOffset === 0) {
        header.current.classList.remove("scrollHeader");
      }

      window.document.body.style.overflowY = "scroll";
      if (currentLink !== undefined) {
        currentLink.classList.remove("active8");
      }
      if (currentSubLink !== undefined) {
        currentSubLink.classList.remove("active8");
      }

      // currentLink.classList.remove('active')
    }
  }
  function handleSidebar2MouseLeave() {
    sidebar2.current.hidden = true;
    // console.log('sidebar2 leave')
    sidebar.current.focus = true;
    //  if(sidebar2.current.hidden)
    //  {
    //    sidebar.current.hidden=true;
    //    return;
    //  }
    // currentLink.classList.remove('active8');
    // currentSubLink.classList.remove('active8');
    // header.current.classList.remove("scrollHeader");
    window.document.body.style.overflowY = "scroll";
  }
  function handleMoreMouseLeave(event) { }
  function handleMoreMouseOver(event) {
    if (
      currentSubLink !== undefined &&
      currentSubLink.classList.contains("active8")
    ) {
      currentSubLink.classList.remove("active8");
    }

    // console.log("mouse", event.target);
    currentSubLink = event.target;
    if (!currentSubLink.classList.contains('icon')) {
      currentSubLink.classList.add("active8");
    }

    // console.log("link event", event.target.innerHTML);

    if (event.target.innerHTML.includes("Media")) {
      // console.log("inside media");
      more_media_sub_links.current.hidden = false;
      more_event_sub_links.current.hidden = true;
      about_innovation_sub_links.current.hidden = true;
      about_services_sub_links.current.hidden = true;
    } else if (event.target.innerHTML.includes("Events")) {
      // console.log("inside events");
      more_media_sub_links.current.hidden = true;
      about_innovation_sub_links.current.hidden = true;
      about_services_sub_links.current.hidden = true;
      more_event_sub_links.current.hidden = false;
    } else if (event.target.innerHTML.includes("Innovations")) {
      // console.log("inside innovations");
      more_media_sub_links.current.hidden = true;
      more_event_sub_links.current.hidden = true;
      about_innovation_sub_links.current.hidden = false;
      about_services_sub_links.current.hidden = true;
    } else if (event.target.innerHTML.includes("Services")) {
      // console.log("inside services");
      more_media_sub_links.current.hidden = true;
      more_event_sub_links.current.hidden = true;
      about_innovation_sub_links.current.hidden = true;
      about_services_sub_links.current.hidden = false;
    } else {
      sidebar2.current.hidden = true;

      return;
    }

    sidebar2.current.focus = true;
    //document.body.style.overflowY = "hidden";
    sidebar2.current.hidden = false;
    //  sidebar.style.left = 400 + "px";
  }
  function myScroll() {
    if (window.pageYOffset > 100) {

      header.current.classList.add("scrollHeader");

    }
    if (window.pageYOffset < 100) {
      if (sidebar.current.hidden)
        header.current.classList.remove("scrollHeader");
    }
    if (window.pageYOffset > 300) {
      topBtn.current.style.display = "block";
    } else {
      topBtn.current.style.display = "none";
    }
  }
  function topFunction() {
    document.body.scrollTo({
      top: 0,
      behavior: "smooth"
    }) // For Safari
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth"
    })// For Chrome, Firefox, IE and Opera
  }

  return (

    <div >
      <header style={{ marginLeft: "0px" }}
        id="headerhm"
        className="headerhm row"
        ref={header}
        onMouseLeave={headerMouseLeave}
        onMouseOver={headerMouseOver}
      >
        <div
          style={{ cursor: "pointer" }}
          className="d-lg-none "
          id="menuBtn"
          onClick={showMenu}
        >
          <div className="bar1" />
          <div className="bar2" />
          <div className="bar3" />
        </div>

        <nav id="nav" className="nav">
          <a
            className="nav-link mr-3"
            href=""
            style={{ padding: "5px" }}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
          >
            About Us
          </a>

          <a
            target="_blank"
            className="nav-link mr-3"
            href="https://growdiesel.in/webinar/"
            style={{ padding: "5px" }}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            rel="noreferrer"
          >
            Education
          </a>

          <a
            target="_blank"
            className="nav-link mr-3"
            href="https://ecostore.growdiesel.com/"
            style={{ padding: "5px" }}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            rel="noreferrer"
          >
            Eco Store
          </a>
          <a
            className="nav-link "
            href="#"
            style={{ padding: "5px" }}
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
          >
            More
          </a>
        </nav>
        <div className="offset-sm-2  col-sm-2 ">
          <NavLink className="navbar-brand" to="/">
            <img src="About/2 copy.png" id="logohai" style={{ width: "200px", marginTop: "7px" }} alt="logomain" />
          </NavLink>
        </div>
        <div id="headerBtn" className="col-sm-3 mx-auto">
          <button className="btn btnSubscribe my-3"><a href="https://growdiesel.in/webinar/" target="_blank" rel="noreferrer" style={{color:"white"}}>SUBSCRIBE</a></button>
          <button className="btn btnSign my-3">Sign In</button>
        </div>
      </header>
      <div
        id="sidebar"
        ref={sidebar}
        className="sidenav"
        onMouseLeave={handleSidebarMouseLeave}
        hidden
      >
        <div >
          <div id="more_sub_links" ref={more_sub_links} hidden>
            <Link
              to="/"
              className="link1"
              onMouseOver={handleMoreMouseOver}
              onMouseLeave={handleMoreMouseLeave}
            >
              Media

            </Link>
            <Link
              to="/sessions"
              className="link1"
              onMouseOver={handleMoreMouseOver}
              onMouseLeave={handleMoreMouseLeave}
            >
              Invite us at your event
            </Link>

            <Link
              to="/vendor"
              className="link1"
              onMouseOver={handleMoreMouseOver}
              onMouseLeave={handleMoreMouseLeave}
            >
              'green' Opportunities
            </Link>
            <Link
              to="/online"
              className="link1"
              onMouseOver={handleMoreMouseOver}
              onMouseLeave={handleMoreMouseLeave}
            >
              Events

            </Link>
            <Link
              to="/blog"
              className="link1"
              onMouseOver={handleMoreMouseOver}
              onMouseLeave={handleMoreMouseLeave}
            >
              Blog
            </Link>
            <Link
              to="/offline"
              className="link1"
              onMouseOver={handleMoreMouseOver}
              onMouseLeave={handleMoreMouseLeave}
            >
              Forum
            </Link>

            <Link
              to="/"
              className="link1"
              onMouseOver={handleMoreMouseOver}
              onMouseLeave={handleMoreMouseLeave}
            >
              Circulars
            </Link>
            <Link
              to="/contact"
              className="link1"
              onMouseOver={handleMoreMouseOver}
              onMouseLeave={handleMoreMouseLeave}
            >
              Contact Us
            </Link>
            <a
              href="https://chat.whatsapp.com/Htnroc2KfzREpY24FtsddS"
              target="_blank"
              className="link1"
              onMouseOver={handleMoreMouseOver}
              onMouseLeave={handleMoreMouseLeave}
              rel="noreferrer"
            >

              <i className="fa fa-whatsapp link1 icon whatsappIcon" style={{ fontSize: "35px" }}></i>

              Join our whatsapp group
            </a>
            <a

              className="link1"
              onMouseOver={handleMoreMouseOver}
              onMouseLeave={handleMoreMouseLeave}
              style={{ cursor: 'default', position: 'absolute', bottom: '63px' }}
              id="socialIcons"
            >
              Support us on social media<span
                className="link1 icon" style={
                  { display: "flex" }
                }>

                <a className="link1 icon" href="https://www.youtube.com/user/growdiesel" target="_blank" rel="noreferrer">
                  <i class="fa fa-youtube-play link1" style={{ fontSize: "35px" }}></i>
                </a>
                <a className="link1 icon" href="https://www.instagram.com/growdiesel/?hl=en" target="_blank" rel="noreferrer">
                  <i class="fa fa-instagram link1" style={{ fontSize: "35px" }}></i>
                </a>
                <a className="link1 icon" href="https://twitter.com/growdiesel?lang=en" target="_blank" rel="noreferrer">
                  <i class="fa fa-twitter link1" style={{ fontSize: "35px" }}></i>
                </a>
                <a className="link1 icon" href="https://www.facebook.com/growdiesel" target='_blank' rel="noreferrer">
                  <i class="fa fa-facebook link1" style={{ fontSize: "35px" }}></i>
                </a>
                <a className="link1 icon" href="https://www.linkedin.com/company/growdiesel-ventures/" target="_blank" rel="noreferrer">
                  <i class="fa fa-linkedin-square  link1" style={{ fontSize: "35px" }}></i>
                </a>
              </span>
            </a>
          </div>
          <div id="about_sub_links" ref={about_sub_links} hidden>

            <Link
              to="/projects"
              className="link1"
              onMouseOver={handleMoreMouseOver}
              onMouseLeave={handleMoreMouseLeave}
            >
              Innovations

            </Link>
            <Link
              to="/story"
              className="link1"
              onMouseOver={handleMoreMouseOver}
              onMouseLeave={handleMoreMouseLeave}
            >
              Our 'green' Story
            </Link>
            <Link
              to="/journey"
              className="link1"
              onMouseOver={handleMoreMouseOver}
              onMouseLeave={handleMoreMouseLeave}
            >
              Our 'green' Journey
            </Link>

            <Link
              to="/story"
              className="link1"
              onMouseOver={handleMoreMouseOver}
              onMouseLeave={handleMoreMouseLeave}
            >
              Our Services

            </Link>
            <Link
              to="/team"
              className="link1"
              onMouseOver={handleMoreMouseOver}
              onMouseLeave={handleMoreMouseLeave}
            >
              Team and Think Tank
            </Link>
            <Link
              to="/clients"
              className="link1"
              onMouseOver={handleMoreMouseOver}
              onMouseLeave={handleMoreMouseLeave}
            >
              Our Clients
            </Link>
          </div>
        </div>
      </div>
      <div
        id="sidebar2"
        ref={sidebar2}
        onMouseLeave={handleSidebar2MouseLeave}
        hidden
      >
        <div className="sidenav2">
          <div id="more_media" ref={more_media_sub_links} hidden>
            <Link
              to="/photo"
              style={{
                backgroundImage: `url(
                  "https://images.pexels.com/photos/1002703/pexels-photo-1002703.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                )`, color: 'white',
              }}
            >
              <span>PHOTOS</span>
            </Link>

            <Link
              to="/videos"
              style={{
                backgroundImage: `url(
                  "https://images.pexels.com/videos/2758322/free-video-2758322.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                )`, color: 'black',
              }}
            >
              <span>VIDEOS</span>
            </Link>
            <Link
              to="/news"
              style={{
                backgroundImage: `url(
                  "https://images.squarespace-cdn.com/content/v1/56f2fcec62cd94748f483cff/1459621207980-38PPKKYHPW3S00GBHQ51/ke17ZwdGBToddI8pDm48kJBOASV7Rs0lnoorGGRRXhVZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZamWLI2zvYWH8K3-s_4yszcp2ryTI0HqTOaaUohrI8PIwHC4pmm7fjhHEq3ygnr5lPS--4qhkU92g_jg32JITRI/news.gif?format=2500w"
                )`,
              }}
            >
              <span>NEWS</span>
            </Link>
          </div>
          <div id="link1" ref={more_event_sub_links} hidden>
            <Link to="/online">Online Events</Link>

            <Link to="/offline">Offline Events</Link>
          </div>
          <div id="link1" ref={about_innovation_sub_links} hidden>
            <Link to="/organic">Organic Waste to Biofulel</Link>
            <Link to="/plastic">Plastic Waste to Biofulel</Link>

            <Link to="/clinic">Medical Mobile Clinic</Link>
            <Link to="/cleanair">Polluted Air to Clean Air</Link>
            <Link to="/gets">Electric Transportation</Link>
          </div>
          <div id="link1" ref={about_services_sub_links} hidden>
            <Link to="#services">Turnkey bio-refinery Project</Link>
            <Link to="#services">Consultancy</Link>

            <Link to="/trainings">Training</Link>
            <Link to="/sessions">Keynote Sessions</Link>
            <Link to="/clinic">Medical Mobile Clinic</Link>
          </div>
        </div>
      </div>
      {/* mobile navbar from here */}
      <nav className="slide-menu open-menu" id="example-menu" ref={mobileSideNav}>
        <div className="controls">
          <button
            type="button"
            className="btn slide-menu__control"
            data-action="back"
          >
            Back
          </button>
          <button
            type="button"
            className="btn slide-menu__control"
            data-action="close"
            onClick={closeMenu}
          >
            Close
          </button>
        </div>
        <ul>
          <li>
            <a href="#">About Us</a>
            <ul>
              <li>
                <Link to="/story">Our 'green' Story</Link>
              </li>
              <li>
                <Link to="/journey">Our 'green' Journey</Link>
              </li>
              <li>
                <a href="#">Innovations</a>
                <ul>
                  <li>
                    <Link to="/organic">Organic Waste to biofuel</Link>
                  </li>
                  <li>
                    <Link to="/plastic">Plastic Waste to biofuel</Link>
                  </li>
                  <li>
                    <Link to="/clinic">Medical Mobile Clinic</Link>
                  </li>
                  <li>
                    <Link to="/cleanair">Polluted Air to Clean Air</Link>
                  </li>
                  <li>
                    <Link to="/gets">Electric Transportation</Link>
                  </li>
                </ul>
              </li>

              <li>
                <a href="#">Our Services</a>
                <ul>
                  <li>
                    <a href="#">Turnkey bio-refinery project</a>
                  </li>
                  <li>
                    <a href="#">Consultancy</a>
                  </li>
                  <li>
                    <Link to="/trainings">Trainings</Link>
                  </li>
                  <li>
                    <Link to="/sessions">Keynote Sessions</Link>
                  </li>
                  <li>
                    <Link to="/clinic">Medical Mobile Clinic</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/team">Team and Think Tank</Link>
              </li>
              <li>
                <Link to="/clients">Our Clients</Link>
              </li>
            </ul>
          </li>
          <li>
            <a target="_blank" href="https://growdiesel.in/webinar/" rel="noreferrer">Education</a>
          </li>
          <li>
            <a target="_blank" href="https://ecostore.growdiesel.com/" rel="noreferrer">Eco Store</a>
          </li>
          <li>
            <a href="/about">More</a>
            <ul>
              <li>
                <a href="#">Media</a>
                <ul id="mobile_media_sublinks">
                  <li>
                    <Link
                      to="/photo"
                      className="mobile_media_sublinks"
                      style={{
                        backgroundImage: `url(
                          "https://images.pexels.com/photos/1002703/pexels-photo-1002703.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                        )`, color: 'white',
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          left: "40%",
                          top: "25%",
                          color: "white"
                        }}
                      >
                        Photos
                      </span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/videos"
                      className="mobile_media_sublinks"
                      style={{
                        backgroundImage: `url(
                          "https://images.pexels.com/videos/2758322/free-video-2758322.jpg?auto=compress&cs=tinysrgb&dpr=1&w=500"
                        )`, color: 'black',
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          left: "40%",
                          top: "25%",
                        }}
                      >
                        Videos
                      </span>
                    </Link>
                    <Link
                      to="/news"
                      className="mobile_media_sublinks"
                      style={{
                        backgroundImage: `url(
                          "https://images.squarespace-cdn.com/content/v1/56f2fcec62cd94748f483cff/1459621207980-38PPKKYHPW3S00GBHQ51/ke17ZwdGBToddI8pDm48kJBOASV7Rs0lnoorGGRRXhVZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZamWLI2zvYWH8K3-s_4yszcp2ryTI0HqTOaaUohrI8PIwHC4pmm7fjhHEq3ygnr5lPS--4qhkU92g_jg32JITRI/news.gif?format=2500w"
                        )`,
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          left: "40%",
                          top: "25%",
                        }}
                      >
                        News
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/sessions">Invite us at your event</Link>
              </li>
              <li>
                <Link to="/vendor">'green' Opportunities</Link>
              </li>
              <li>
                <a href="#"> Events </a>             <ul>
                  <li>
                    <Link to="/online">Online Events</Link>
                  </li>
                  <li>
                    <Link to="/offline">Offline Events</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/">Forum</Link>
              </li>
              <li>
                <Link to="/">Circulars</Link>
              </li>
              <li>
                <Link to="/contact">Contact us</Link>
              </li>
              <li>
                <a href="https://chat.whatsapp.com/Htnroc2KfzREpY24FtsddS" target="_blank" rel="noreferrer">

                  Join our whatsapp group </a>
              </li>
              <li>
                <a>Support us on social media
                  <span
                    className="link1 icon" style={
                      { display: "flex" }
                    }>
                    <a className="link1 icon" href="https://www.youtube.com/user/growdiesel" target="_blank" rel="noreferrer">
                      <i class="fa fa-youtube-play link1" style={{ fontSize: "35px" }}></i>
                    </a>
                    <a className="link1 icon" href="https://www.instagram.com/growdiesel/?hl=en" target="_blank" rel="noreferrer">
                      <i class="fa fa-instagram link1" style={{ fontSize: "35px" }}></i>
                    </a>
                    <a className="link1 icon" href="https://twitter.com/growdiesel?lang=en" target="_blank" rel="noreferrer">
                      <i class="fa fa-twitter link1" style={{ fontSize: "35px" }}></i>
                    </a>
                    <a className="link1 icon" href="https://www.facebook.com/growdiesel" target='_blank' rel="noreferrer">
                      <i class="fa fa-facebook link1" style={{ fontSize: "35px" }}></i>
                    </a>

                  </span>
                </a>

              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <button id="scrollToTopBtn" ref={topBtn} onClick={topFunction}>^</button>
    </div >

  );
}
