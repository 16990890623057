import React from "react";
import "./styles/Image.css";

function Imagessection() {
  return (
    <div>
      <h2 className="text-center heading">GOVERNMENT ORGANISATIONS SERVED</h2>
      <div className="container-fluid my-4">
        <marquee behavior="alternate" direction="right" className="jamun"  >
          {/* row1 */}
          <div>
            <img
              src="gmt/1.png"
              alt="delhi govt logo"
              width="250px"
              height="250px"
              className="mx-auto img"
              key="1"
            />
            <img
              src="gmt/2.png"
              alt="mnmc"
              // width="300px"
              // height="300px"
              width="250px"
              height="250px"
              className="mx-auto img"
              key="2"
            />

            <img
              src="gmt/20gym.png"
              alt="Gym khana club"
              // width="300px"
              // height="300px"
              width="250px"
              height="250px"
              className="mx-auto img"
              key="3"
            />
            <img
              src="gmt/railways.png"
              alt="INDIAN RAILWAYS"
              // width="300px"
              // height="300px"
              width="250px"
              height="250px"
              className="mx-auto img"
              key="4"
            />
            <img
              src="gmt/5.png"
              // width="300px"
              // height="300px"
              width="250px"
              height="250px"
              className="mx-auto img"
              key="5"
            />
            <img
              src="gmt/aiims.png"
              alt="AIIMS"
              // width="300px"
              height="250px"
              width="250px"
              // height=""
              className="mx-auto img"
              key="6"
            />

            <img
              src="gmt/10.png"
              alt="DRDO"
              width="250px"
              height="250px"
              className="mx-auto img"
              key="10"
            />
            <img
              src="gmt/11.png"
              width="250px"
              height="250px"
              className="mx-auto img"
              alt="SEPF"
              key="11"
            />
            <img
              src="gmt/15.png"
              width="250px"
              height="250px"
              className="mx-auto img"
              alt="BARC"
              key="12"
            />
            <img
              src="gmt/14.png"
              alt="OILINDIA"
              width="250px"
              // height="250px"
              className="mx-auto img"
              key="13"
              // style={{ marginTop: "13px" }}
            />
            <img
              src="gmt/12.png"
              alt="INDIAN ARMY"
              width="250px"
              height="250px"
              className="mx-4 img"
              key="14"
            />
            <img
              src="gmt/16.png"
              alt="GAIL"
              width="250px"
              height="250px"
              className="mx-auto img"
              key="15"
            />
            <img
              src="gmt/17.png"
              alt="GOVT. of maharstra "
              width="250px"
              height="250px"
              className="mx-auto img"
              key="16"
            />
            <img
              src="gmt/18.png"
              width="250px"
              height="250px"
              className="mx-auto img"
              alt="MCD"
              key="17"
            />
            <img
              src="gmt/19.png"
              alt="MNRE"
              width="250px"
              height="250px"
              className="mx-auto img"
              key="18"
            />
            <img
              src="gmt/3.png"
              alt="Karnal Municipal"
              width="250px"
              height="250px"
              className="mx-auto img"
              key="19"
            />
             <img
              src="gmt/railways.png"
              alt="INDIAN RAILWAYS"
              // width="300px"
              // height="300px"
              width="250px"
              height="250px"
              className="mx-auto img"
              key="27"
            />
            <img
              src="gmt/22.png"
              width="250px"
              height="250px"
              className="mx-auto img"
              alt="bharat ptrolium"
              key="20"
            />
            <img
              src="gmt/dpcc.png"
              alt="DPCC"
              width="250px"
              // height="250px"
              className="mx-auto img"
              key="7"
              // style={{ marginTop: "60px" }}
            />
            <img
              src="gmt/8.png"
              width="250px"
              // height="250px"
              className="mx-4 img"
              alt="SCGJ"
              key="8"
              // style={{ marginTop: "120px" }}
            />
            <img
              src="gmt/9.png"
              alt="SCIR"
              width="250px"
              // height="250px"
              className="mx-auto img"
              key="9"
              // style={{ marginTop: "46px" }}
            />
          </div>
          {/* row2 */}
        </marquee>
      </div>
    </div>
  );
}

export default Imagessection;
