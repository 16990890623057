import React from "react";

function Online() {
  return (
    <div>
      <div className="header">
        <div className="text">
          <h1>ONLINE EVENTS</h1>
        </div>
      </div>
      <div className="container my-4">
        <div className="card" style={{ border: "1px solid black" }}>
          <img
            src="https://images.unsplash.com/photo-1463320898484-cdee8141c787?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
            width="100%"
            height="300px"
            alt="growdiesel"
          ></img>
          <h3
            className="text-left"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
          >
            Start Your Green Startup
          </h3>
          <p
            className="text-left"
            style={{ paddingLeft: "30px", paddingRight: "30px" }}
          >
            In this session, you will learn about different ways on how you can
            begin a green startup. In this session you will learn how to get
            funding, technology and market access.
          </p>
          <div
            className="row "
            style={{ paddingLeft: "30px", paddingRight: "30px" }}
          >
            <div className="col-lg-4 col-sm-12 mx-auto">
              <p className="text-left">
                <span className="font-weight-bold">DATE: </span>22/02/2021
                (Sunday)
              </p>
              <p className="text-left">
                {" "}
                <span className="font-weight-bold">Time: </span> 5:30 pm – 6:30
                pm
              </p>
              <p className="text-left">
                {" "}
                <span className="font-weight-bold">Venue: </span> Online
              </p>
            </div>
            <div className="col-lg-4 col-sm-12 mx-auto">
              <p className="text-left">
                {" "}
                <span className="font-weight-bold">Instructor: </span> Mr. Anmol
                Dhamija
              </p>
              <p className="text-left">
                <span className="font-weight-bold">Total seats: </span> 50
              </p>
              <p className="text-left">
                <span className="font-weight-bold">Ticket Price: </span>{" "}
                Cost/Free
              </p>
            </div>
            <div className="col-lg-4 col-sm-12 mx-auto">
              <button
                style={{
                  border: "0px",
                  backgroundColor: "#3ded97",
                  padding: "13px",
                  fontWeight: "bold",
                }}
              >
                Register Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Online;
