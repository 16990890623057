import React,{useEffect} from "react";
import ".././styles/Sessions.css";
// import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";

function Sessions() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <div className="header">
        <div className="text">
          <h1 className="major">KEYNOTE SESSIONS/GUEST/LECTURES</h1>
        </div>
      </div>
      <div
        className="container-fluid my-3"
        style={{ backgroundColor: "black" }}
      >
        <div className="row ">
          <div className="col-lg-4 col-sm-12 bg-white">
            <img src="About/0. Atul Saxena (Founder).jpg" className="amz img-fluid" alt="atul saxena"></img>
          </div>
          <div className="col-lg-8 col-sm-12">
            <div className="card bg-white">
              <div className="row">
                <p className="utk" style={{ fontSize: "18px" }}>
                  700+
                </p>
                <p className="green" style={{ fontSize: "18px" }}>
                  SESSIONS TAKEN IN
                </p>
                <p className="utk" style={{ fontSize: "18px" }}>
                  10+ COUNTRIES
                </p>
              </div>
              <p className="mx-3 opop">
                Mr. Atul Saxena is a social entrepreneur, the founder of a
                renewable fuel company and managing trustee of Growdiesel
                Climate Care Council. Atul has been a hard core hands-on person
                with a natural instinct and flair to visualize the market or
                societal needs of times to come and can therefore foresee the
                changes and evolvement of the business landscape that is yet to
                happen. His Bachelor’s degree from NDRI, India provided him with
                an integrated education base and insights into the fields
                starting from dairy farming & village extension to milk and food
                processing to process engineering & equipment to project
                management & marketing. His early stint with Parle Agro can
                itself boast of a major achievement as he was instrumental in
                giving shape to large scale Maaza Mango manufacturing projects
                from concept to commissioning. He truly believes in 3P projects.
                Projects for ‘Planet’ ,‘People’ and ‘Profits’. All projects he
                has ever executed after starting Growdiesel have followed this
                approach. He is an official advisor to Ministry of Micro, Small
                and Medium Enterprise (MSME) and is in the governing council of
                Skill Council for Green Jobs (SCGJ) by Ministry of New and
                Renewable Energy. He is considered as India’s one of the most
                experienced people in Indian biofuel sector.
              </p>
              <div className="row">
                <p className="mx-auto green" style={{ fontSize: "20px" }}>
                  Rs. 1,00,000 + taxes
                </p>
                <div className="mx-auto">
                  <button className=" book" style={{ fontSize: "20px" }}>
                    BOOK HIM
                  </button>
                </div>
                <div className="mx-auto">
                  <button className=" green" style={{ fontSize: "18px" }}>
                    DOWNLOAD FULL PROFILE
                  </button>
                </div>
              </div>
              <div className="ish">
                <div className="row">
                  <p className="green" style={{ fontSize: "18px" }}>
                    TOPICS
                  </p>
                  <p className="mx-auto utk">BIO-CNG</p>
                  <p className="mx-auto utk">BIO-CNG</p>
                  <p className="mx-auto utk">BIO-DIESEL</p>
                  <p className="mx-auto utk">BIOFUELS</p>
                </div>

                <div className="row">
                  <p className="mx-auto utk">ELECTRIC VEHICLE CHARGING</p>
                  <p className="mx-auto utk">BIOFUELS</p>
                  <p className="mx-auto utk">WASTE TO ENERGY</p>
                  <p className="mx-auto utk">RENEWABLE ENERGY</p>
                </div>
                <div className="row">
                  <p className="mx-auto utk">WASTE MANAGEMENT</p>
                  <p className="mx-auto utk">SUSTAINABILITY</p>
                  <p className="mx-auto utk">CLMATE CHANGE</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header docomo" ></div>
      {/* <div className="empty"></div> */}
      <div
        className="container-fluid my-3"
        style={{ backgroundColor: "black" }}
      >
        <div className="row ">
          <div className="col-lg-4 col-sm-12 bg-white">
            <img src="gallery/shreymain.jpeg" className="amz img-fluid" alt="shrey saxena"></img>
          </div>
          <div className="col-lg-8 col-sm-12">
            <div className="card bg-white">
              <div className="row">
                <p className="utk" style={{ fontSize: "18px" }}>
                  100+
                </p>
                <p className="green" style={{ fontSize: "18px" }}>
                  SESSIONS TAKEN IN
                </p>
                <p className="utk" style={{ fontSize: "18px" }}>
                  5 COUNTRIES
                </p>
              </div>
              <p className="mx-3 opop">
                Shrey is a second generation social entrepreneur at Growdiesel.
                He is an engineer and one of the top global applicants to be
                shortlisted at Stanford University for his professional
                education. He is a TEDx speaker and his first stint with social
                entrepreneurship was at the age of 14 in the computer lab of
                Cambridge School. His venture generates an additional 6 figure
                revenue for the school every year since 2013. He is an
                internationally recognised public speaker and has worked with
                various organisations in the past since the age of 18. He has
                taken multiple sessions on social entrepreneurship and
                communication at various organisations such as HCL, Adobe,
                Monotype, Stanford, IIT Delhi, IIT Bombay, IIM-Calcutta,
                IIM-Shilling, Sonalika tractors, Amity University etc. He has
                served as an area director for non-profit District 41,
                Toastmasters International which caters to North India, East
                India, North-East India, Nepal, Bhutan and Bangladesh and has
                mentored over 50 individuals personally. In his tenure with the
                organisation , he has helped to raise a 7 figure revenue for the
                organisation. He has worked with Red Bull as a marketing and
                branding specialist and handled their activities in 4 European
                countries namely Spain, France, Belgium and Netherlands. He was
                among the top 6 people from India to be selected for this
                campaign. He also does pro-bono keynote sessions.
              </p>
              <div className="row">
                <p className="mx-auto green" style={{ fontSize: "20px" }}>
                  Rs. 25,000 + taxes
                </p>
                <div className="mx-auto">
                  <button className=" book" style={{ fontSize: "20px" }}>
                    BOOK HIM
                  </button>
                </div>
                <div className="mx-auto">
                  <button className=" green" style={{ fontSize: "18px" }}>
                    DOWNLOAD FULL PROFILE
                  </button>
                </div>
              </div>
              <div className="ish">
                <div className="row">
                  <p className="green" style={{ fontSize: "18px" }}>
                    TOPICS
                  </p>
                  <p className="mx-auto utk">ENTREPRENEURSHIP</p>
                  <p className="mx-auto utk">RENEWABLE ENERGY</p>
                  <p className="mx-auto utk">BIO-CNG</p>
                  {/* <p className="mx-auto utk">BIOFUELS</p> */}
                </div>

                <div className="row">
                  <p className="mx-auto utk">WASTE TO ENERGY</p>
                  <p className="mx-auto utk">TRAVEL</p>
                  <p className="mx-auto utk">COMMUNICATION SKILLS</p>
                  <p className="mx-auto utk">SUSTAINABILITY</p>
                </div>
                <div className="row">
                  <p className="mx-auto utk">WASTE MANAGEMENT</p>
                  <p className="mx-auto utk">FAMILY BUSINESS</p>
                  <p className="mx-auto utk">CLMATE CHANGE</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="start"></div>
      <div className="parallax ">
        <div className="body text-center">
          <h1 className="text-white paralaxconmtent">
            “Become a part of the{" "}
            <span id="span" style={{ color: "#3ded97" }}>
              fuel revolution.”
            </span>{" "}
            <span id="span" style={{ color: "#3ded97" }}></span>
          </h1>
          <div>
            <button className="btnknow">Know More</button>
          </div>
        </div>
      </div>
      <div className="start"></div>
    </div>
  );
}

export default Sessions;
