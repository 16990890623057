import React from "react";
import "./styles/Video.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import {NavLink} from "react-router-dom";

function Video() {
  return (
    <div className="my-5">
      <h1 className="text-center heading my-4">INNOVATIONS</h1>
      <Carousel>
        <Carousel.Item>
          <div className="conatiner mx-auto">
            <div className="row container mx-auto">
              <div className="col-lg-4 col-sm-12">
                <div className="card bg">
                  <iframe
                    width="100%"
                    title="Biofuel"
                    height="200px"
                    src="https://www.youtube.com/embed/dAII5rNgmNQ"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <div className="card-body">
                    <h5 className="title mb-4">
                      ORGANIC WASTE <br></br>TO BIOFUEL<br></br>
                    </h5>

                    <NavLink to="/organic" className="btnmain  w-100">
                      Learn More
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 md-4 col-sm-12">
                <div className="card bg">
                  <iframe
                    height="200px"
                    src="https://www.youtube.com/embed/DLBHLXHTc3M"
                    frameborder="0"
                    title="Clean Air"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <div className="card-body">
                    <h5 className="title mb-4">
                      KALPAVRIKSHA
                      <br />
                      (CLEAN AIR)
                    </h5>

                    <NavLink to="/cleanair" className="btnmain w-100">
                      Learn More
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-4  col-sm-12 ">
                <div className="card bg">
                  <iframe
                    width="100%"
                    height="200"
                    src="https://www.youtube.com/embed/qeKSUIdW-Qs"
                    frameborder="0"
                    title="RENEWABLE ELECTRIC TRANSPORTATION"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <div className="card-body">
                    <h6 className=" title mb-4">
                      RENEWABLE ELECTRIC TRANSPORTATION
                    </h6>

                    <NavLink to="/gets" className="btnmain  w-100">
                      Learn More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="conatiner ">
            <div className="row container mx-auto">
              <div className="col-lg-4 md-4 col-sm-12 mx-auto">
                <div className="card bg">
                  <iframe
                    // width="560"
                    // height="315"
                    height="200px"
                    src="https://www.youtube.com/embed/dAII5rNgmNQ"
                    frameborder="0"
                    title="Plastic Waste"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <div className="card-body">
                    <h5 className="text-uppercase title mb-4">
                      Plastic Waste<br></br> to BIOFUEL
                    </h5>

                    <NavLink to="/plastic" className="btnmain  w-100">
                      Learn More
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 md-4 col-sm-12 mx-auto">
                <div className="card bg">
                  <iframe
                    width="100%"
                    height="200"
                    src="https://www.youtube.com/embed/ZtssHoxSsKg"
                    frameborder="0"
                    title="KALPAVRIKSHA"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <div className="card-body">
                    <h5 className="title mb-4">
                      KALPAVRIKSHA
                      <br />
                      (CLEAN AIR)
                    </h5>

                    <NavLink to="/cleanair" className="btnmain w-100">
                      Learn More
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Video;
