import React from "react";
import "./styles/Top.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";

function Top() {
  return (
    <>
      <div style={{ marginBottom: "130px", position: "relative", top: "75px" }}>
        <div classname="slidermain">

          <Carousel >
            <Carousel.Item className="caro">
              <img
                src="Slider/1a.jpg"
                alt="helo"
                width="100%"

                className="backgoundimg"
              ></img>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src="Slider/2.jpg"
                alt="helo"
                width="100%"

                className="backgoundimg"
              ></img>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src="Slider/3.jpg"
                alt="helo"
                width="100%"
                className="backgoundimg"
              ></img>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src="Slider/4.jpg"
                alt="helo"
                width="100%"
                className="backgoundimg"
              ></img>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src="Slider/5.jpg"
                alt="helo"
                width="100%"
                className="backgoundimg"
              ></img>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src="Slider/6.jpg"
                alt="helo"
                width="100%"
                className="backgoundimg"
              ></img>
            </Carousel.Item>
          </Carousel>
        </div>
        <div class="main-text hidden-xs ">
          <div className="text-left">
            <div className="animate__animated  animate__lightSpeedInRight">
              <h1 id="basic2" style={{ fontWeight: "bold" }}>
                <span style={{ color: "#3ded97" }}> At</span> Growdiesel,
                <span style={{ color: "#3ded97" }}>
                  We Are Fighting Climate
                </span>{" "}
                <br></br>
                <span style={{ color: "#3ded97" }}>
                  Change By Converting
                </span>{" "}
                <br></br>
                Waste <span style={{ color: "#3ded97" }}>2 </span> Wealth
              </h1>
              <div className="text-left">
                <hr
                  className="w-50 text-left"
                  style={{
                    backgroundColor: "#3ded97",
                    height: "4px",
                    marginLeft: "35px",
                  }}
                ></hr>
              </div>

              <div className="animate__animated animate__lightSpeedInRight animate__slow	2s">
                <h4 id="basic" className="my-3 font-weight-bold">
                  Since 2001, we are working for a{" "}
                  <span style={{ color: "#3ded97" }}>sustainable future.</span>
                </h4>
                <h4 id="basic" className="my-3 font-weight-bold">
                  A future, where{" "}
                  <span style={{ color: "#3ded97" }}>clean fuels</span> and
                  <span style={{ color: "#3ded97" }}> clean energy</span> are
                  used.
                </h4>
                <h4 id="basic" className="my-3 font-weight-bold">
                  A future, where our coming generations can have a
                  <span style={{ color: "#3ded97" }}> cleaner</span> and{" "}
                  <span style={{ color: "#3ded97" }}>greener earth.</span>
                </h4>
                <h4 id="basic" className="my-3 font-weight-bold">
                  We are building a team of{" "}
                  <span style={{ color: "#3ded97" }}>climate saviours</span> who
                  <span style={{ color: "#3ded97" }}>
                    {" "}
                    want to get paid for saving environment.
                  </span>
                </h4>
              </div>
              <div className="my-4 animate__animated animate__lightSpeedInRight animate__slow	3s">
                <a href="https://growdiesel.in/webinar/" target="_blank" rel="noreferrer"> <button className="btn-grad">
                  Attend Waste 2 Wealth<br></br> Masterclass
                </button></a>
                <a href="https://ecostore.growdiesel.com/" target="_blank" rel="noreferrer">

                  <button className="hellobtn2">
                    Purchase Eco-friendly <br></br>Products
                </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Top;
