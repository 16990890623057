import React, { useEffect } from "react";
import ".././styles/Clients.css";
import Carousel from "react-bootstrap/Carousel";

function Clients() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>

      <div className="header">
        <div className="text1">
          <div className="bor"></div>
          <h1 className="major">OUR PRESTIGIOUS CLIENTS</h1>
        </div>
      </div>
      <div className="bor"></div>
      <h1 className="heading  mb-4">GOVERNMENT ORGANISATIONS SERVED</h1>
      <Carousel>
        <Carousel.Item>
          <div className="row">
            <img
              src="gmt/1.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
            <img
              src="gmt/2.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
            <img
              src="gmt/3.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
            <img
              src="gmt/5.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row">
            <img
              src="gmt/17.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
            <img
              src="gmt/18.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
            <img
              src="gmt/19.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
            <img
              src="gmt/20gym.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row">
            <img
              src="gmt/12.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
            <img
              src="gmt/9.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
            <img
              src="gmt/10.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
            <img
              src="gmt/11.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row">
            <img
              src="gmt/13.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
            <img
              src="gmt/14.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
            <img
              src="gmt/15.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
            <img
              src="gmt/16.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row">
            <img
              src="gmt/22.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
            <img
              src="gmt/aiims.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
            <img
              src="gmt/dpcc.png"
              alt="company"
              //   height="300px"
              width="300px"
              className="mx-auto"
            />
            <img
              src="gmt/railways.png"
              alt="company"
              height="300px"
              className="mx-auto"
            />
          </div>
        </Carousel.Item>
      </Carousel>
      <div className="bor mt-3"></div>
      <h1 className="heading  mb-4">PRIVATE ORGANISATIONS SERVED</h1>
      <Carousel>
        <Carousel.Item>
          <div className="row">
            <img
              src="pvt/3.png"
              alt="company"
              height="300px"
              width="300px"
              className="mx-auto"
            />
            <img
              src="pvt/1. Sonalika tractors.png"
              alt="company"
              height="300px"
              width="300px"
              className="mx-auto"
            />
            <img
              src="pvt/4.png"
              alt="company"
              height="300px"
              width="300px"
              className="mx-auto"
            />
            <img
              src="pvt/5.png"
              alt="company"
              height="300px"
              width="300px"
              className="mx-auto"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row">
            <img
              src="pvt/6.png"
              alt="company"
              height="300px"
              width="300px"
              className="mx-auto"
            />
            <img
              src="pvt/7.png"
              alt="company"
              height="300px"
              width="300px"
              className="mx-auto"
            />
            <img
              src="pvt/8.png"
              alt="company"
              height="300px"
              width="300px"
              className="mx-auto"
            />
            <img
              src="pvt/9.png"
              alt="company"
              height="300px"
              width="300px"
              className="mx-auto"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row">
            <img
              src="pvt/10.png"
              alt="company"
              height="300px"
              width="300px"
              className="mx-auto"
            />
            <img
              src="pvt/11.png"
              alt="company"
              height="300px"
              width="300px"
              className="mx-auto"
            />
            <img
              src="pvt/13.png"
              alt="company"
              height="300px"
              width="300px"
              className="mx-auto"
            />
            <img
              src="pvt/18.png"
              alt="company"
              height="300px"
              width="300px"
              className="mx-auto"
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="row">
            <img
              src="pvt/14.png"
              alt="company"
              //   height="300px"
              width="300px"
              className="mx-auto"
            />
            <img
              src="pvt/15.png"
              alt="company"
              //   height="300px"
              width="300px"
              className="mx-auto hover"
            />
            <img
              src="pvt/16.png"
              alt="company"
              //   height="300px"
              width="300px"
              className="mx-auto hover"
            />
            <img
              src="pvt/17.png"
              alt="company"
              //   height="300px"
              width="300px"
              className="mx-auto hover"
            />
          </div>
        </Carousel.Item>
      </Carousel>
      {/* <Parallax></Parallax>+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
      <div className="start"></div>
      <div className="parallax ">
        <div className="body text-center ">
          <h1 className="text-white paralaxconmtent">
            “Become a part of the <br></br>
            <span id="span" style={{ color: "#3ded97" }}>
              fuel revolution.”
            </span>{" "}
            <span id="span" style={{ color: "#3ded97" }}></span>
          </h1>
          <div>
            <a href="https://education.growdiesel.com/" target="_blank" rel="noreferrer"><button className="btnknow">Know More</button></a>
          </div>
        </div>
      </div>
      <div className="start"></div>
      {/* value addition done ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/}
    </div>
  );
}

export default Clients;
