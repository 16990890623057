import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

function Gets() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <div className="header">
        <div className="text">
          <h1 className="major">RENEWABLE ELECTRIC TRANSPORTATION SYSTEM</h1>
        </div>
      </div>
      <div className="container">
        <div className="row">
          {/* hkkk */}
          <div className="col-lg-9 col-sm-12">
            <div className="row my-5 happen">
              <h3 className="mb-3 mx-2 jack text-dark">
                GROWDIESEL ELECTRIC TRANSPORTATION SYSTEM (G.E.T.S.)
              </h3>
              <div className="hello mt-2">
                <a className="btn-success p-2" href="pdf/GETS Team profile.pdf" target="_blank">
                  <InsertDriveFileIcon className="mb-1" /> View/Download Company Profiles
                </a>
              </div>
            </div>
            {/* </div> */}
            <iframe
              width="100%"
              height="415"
              className='vansh'
              title=" GROWDIESEL ELECTRIC TRANSPORTATION SYSTEM "
              src="https://www.youtube.com/embed/qeKSUIdW-Qs"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            <h3 className="font-weight-bold mt-3 text-left">
              WHAT IS G.E.T.S.?
            </h3>
            <p className="text-left my-3">
              GROWDIESEL ELECTRIC TRANSPORTATION SYSTEM (G.E.T.S.) was started
              with a belief that to change the future of transportation, an
              innovative solution needs to be adopted. At Growdiesel, we have
              already pioneered “waste to biofuel” technology. Our rich
              experience and commercially successful projects have given us the
              confidence that we can transform the electric mobility world.
            </p>

            <img src="About/gets1.png" width="100%" className="hariram" alt="gets1"></img>
            <p className="text-left my-3">
              After closely working with Delhi government, Maharashtra
              government, Haryana government, Ministry of small and medium
              enterprises (MSME), Ministry of New and Renewable Energy (MNRE),
              we realized that there was a need for an innovative electric
              transportation system . If we are dependent on traditional power
              sources for transport, the industry will not thrive.
            </p>

            <img src="About/gets2.png" width="100%" height="400px" className="hariram" alt="industry"></img>
            <p className="text-left mt-3">
              For electric transportation industry to thrive, innovative sources
              of energy production need to be adopted. Unfortunately, solar
              energy has its own limitations. However, we know that our
              technology is the answer. In 2018, we successfully commissioned a
              project for Haryana government in which we convert waste into
              bio-CNG which gets further converted to electricity. Unlike solar,
              this can be done 24x7.
            </p>
            <img src="About/gets3.png" width="100%" height="400px" className="hariram" alt="gets2"></img>
            <p className="text-left mt-3">
              Our project site in Haryana was inspected by few government
              agencies and they floated us a recommendation that maybe we can do
              something in electric transportation industry. After working and
              intense evaluation of this recommendation, we took it very
              seriously to execute. Hence, we tied up with few organisations to
              put this project on-ground.
            </p>
            <img src="About/get4.png" width="100%" className="hariram" alt="gets3"></img>
            <p className="text-left mt-3">
              <span className="font-weight-bold">
                {" "}
                Growdiesel Electric Transportation System (G.E.T.S)
              </span>{" "}
              is launching its own fleet of electric autos which will run on
              “renewable powered biofuel”. In this system, we will focus to
              establish a renewable fuel generation system, vehicle charging
              infrastructure and battery swapping infrastructure. This will be
              first of its kind project in India. In initial phases, we will be
              launching G.E.T.S. in Haryana, Rajasthan and Delhi. We already
              have stratergic tie-ups with prominent organisations who will be
              partnering with us.
            </p>
            <h3 className="text-left">How is G.E.T.S. different?</h3>
            <p className="text-left">
              The 2 biggest pain points in electric vehicle industry are:
            </p>
            <img src="About/gets5.png" width="100%" className="hariram" alt="gets main"></img>
            <p className="text-left my-3">
              After studying this industry in detail, we realized that the
              biggest problem is the improper charging and even bigger problem
              is heavy battery cost to the driver. Our foremost responsibility
              is to support our driver partner. By associating with G.E.T.S. the
              driver doesn’t have to worry about replacing battery ever. He can
              freely work peacefully without thinking about cost of battery
              replacement.{" "}
              <span className="font-weight-bold ">
                G.E.T.S. will be investing in high quality batteries and
                advanced charging systems through its venture fund.
              </span>
            </p>
            <img src="About/gets6.png" width="100%" className="hariram" alt="about mains "></img>

            <p className="text-left mt-3">
              By associating with G.E.T.S. it is estimated that our driver
              partner will save roughly Rs. 10 lacs over the lifetime that
              otherwise he would have spent on battery replacement. This will
              enable the driver partner to sustain with us. Our foremost
              priority is to give ease to the driver. Our battery swapping
              infrastructure which is currently reserved for future will give
              the driver even more flexibility.
            </p>
            <img src="About/gets7.png" width="100%" height="400px" className="hariram" alt="gets main 2"></img>
            <p className="text-left mt-3">
              Being in the industry since last 20 years, our team of scientists,
              engineers and business, we know how to put all our partners at
              ease. We have a very experienced think tank of professors,
              scientists, IIT-IIM alumnus who are supporting this initiative.
            </p>
            <h2 className="font-weight-bold my-3 text-left">
              G.E.T.S. mobility franchise program Want to
            </h2>
            <img src="About/gets8.png" width="100%" height="400px" className="hariram" alt="getsmain"></img>
            <h3 className="font-weight-bold my-4 text-left">
              Want to own a part of this business?
            </h3>
            <div className="row my-3">
              <h4 className="text-left kashmir">Fill G.E.T.S. franchise form: </h4>
              <div className="kashmir">
                <button className="btn-success">Click Here</button>
              </div>
            </div>
            <div className="row my-2">
              <h4>Contact us- info@growdieselmail.com</h4>



            </div>
          </div>
          {/* end of the division++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
          <div className="col-lg-3 ">
            <div className="my-5">
              <ul className="f1">
                <NavLink to="/" className="poco2">
                  <li className="point2 my-2 font-weight-bold">
                    INNOVATION
                </li>
                </NavLink>
                <NavLink to="/organic" className='poco'>
                  <li className="point my-2 font-weight-bold">
                    ORGANIC WASTE TO BIOFUEL
                </li>
                </NavLink>
                <NavLink to="/plastic" className='poco'>
                  <li className="point my-2 font-weight-bold">
                    {" "}
                    PLASTIC WASTE TO BIOFUEL
                </li>
                </NavLink>
                <NavLink to="/cleanair" className='poco'>
                  <li className="point my-2 font-weight-bold">
                    {" "}
                    KALPVRIKSHA (CLEAN AIR)
                </li>
                </NavLink>
                <NavLink to="/gets" className='poco'>
                  <li className="point my-2 font-weight-bold">
                    {" "}
                    GROWDIESEL ELECTRIC TRANSPORTATION SYSTEM
                </li>
                </NavLink>
              </ul>
            </div>
          </div>
        </div>
      </div>{" "}
      <div
        className="row container-fluid mt-5"
        style={{ backgroundColor: "#3ded97", padding: "20px", marginLeft: "0px" }}
      >
        <div className="col-lg-9">
          <h2 className="font-weight-bold minor">Enter the renewable vehicle charging sector</h2>
          <h2 style={{ color: "red", fontWeight: "bold" }} className="minor">TODAY</h2>
        </div>
        <div className="col-lg-3">
          <a href="https://education.growdiesel.com/" target="_blank" rel="noreferrer">
            <button className="lak">
              <span className='font-weight-bold'>Know More</span>
            </button>
          </a>

        </div>
      </div>
    </div>
  );
}

export default Gets;
